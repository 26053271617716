import { useEffect, useState } from "react";
import AllSections from "../../components/AllSections/AllSections";
import { UserService } from "../../services/user.service";
import useRedirect from "../../hooks/useRedirection";
import { IonSpinner } from "@ionic/react";

const ProfilePage: React.FC<any> = ({ pageConfig }) => {
    const [userDetails, setUserDetails] = useState<any>(null);
    const { redirectTo } = useRedirect();


    useEffect(() => {
        (async () => {
            try {
                const [userDetailsResp]: any = await Promise.allSettled([
                    UserService.getUserDetails()
                ]);
                setUserDetails(userDetailsResp.value.data);
            } catch (error) {
                // setUserDetails(null);
            }
        })();
    }, []);


    const updateProfile = async (section: any, formData: any) => {
        try {
            await UserService.updateUserDetails(formData);
            redirectTo({ redirectionUrl: -1 });
        } catch (error) {
            // Do nothing.
        }
    }


    return (
        <>
            {userDetails ? <AllSections data={userDetails} {...pageConfig?.PROFILE} handleClick={updateProfile} /> : <div className='body-center'>
                <IonSpinner name="circular" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonSpinner>
            </div>}
        </>
    );
};

export default ProfilePage;
