import { AssetsManagerService } from "../services/assets-manager.service";
import { EarningManagerService } from "../services/earning-manager.service";
import { SpendManagerService } from "../services/spend-manager.service";

export const getPassbookData = async (includeAssetDetails = false, selectedDate, includesCashTxn = false) => {
    const [spendsResp, earningsResp, assetsResp]: any = await Promise.allSettled([
        SpendManagerService.getMergedSpendsDetails(selectedDate, includesCashTxn),
        EarningManagerService.getMergedEarningsDetails(selectedDate),
        includeAssetDetails && AssetsManagerService.getMergedAssetDetails(selectedDate)
    ]);

    return mapPassbookItems({
        spends: spendsResp.value,
        earnings: earningsResp.value,
        assetsResp: assetsResp.value
    });
}

export const mapPassbookItems = ({ spends, earnings, assetsResp }) => {
    if (!assetsResp || typeof assetsResp !== 'object' || !assetsResp.investedAccountsInThisMonth || !Array.isArray(assetsResp.investedAccountsInThisMonth)) {
        assetsResp = { investedAccountsInThisMonth: [] };
    }
    const passbookItemsByMonth = {};
    const allItems = [
        ...spends.spendsTransactions,
        ...earnings.earningsTransactions,
        ...assetsResp.investedAccountsInThisMonth,
    ];
    allItems.sort((a: any, b: any) => b.createdAt - a.createdAt);
    allItems.forEach((item: any) => {
        if (item.transactions && item.transactions.length > 0) {
            item.transactions.forEach((transaction: any) => {
                const month = new Date(transaction.date).toLocaleString('default', { month: 'long', year: 'numeric' });
                if (!passbookItemsByMonth[month]) {
                    passbookItemsByMonth[month] = [];
                }
                passbookItemsByMonth[month].unshift({
                    ...item,
                    ...transaction,
                    type: 'investment',
                    amount: transaction.amount,
                    date: transaction.date,
                    note: transaction.note,
                });
            });
        } else {
            const month = new Date(item.date).toLocaleString('default', { month: 'long', year: 'numeric' });
            if (!passbookItemsByMonth[month]) {
                passbookItemsByMonth[month] = [];
            }
            passbookItemsByMonth[month].push(item);
        }
    });

    const sortedPassbookItemsByMonth = Object.keys(passbookItemsByMonth)
        .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
        .reduce((acc, key) => {
            acc[key] = passbookItemsByMonth[key];
            return acc;
        }, {});
    return {
        allItems: sortedPassbookItemsByMonth,
        spendsInCash: spends.spendsInCash
    };
}