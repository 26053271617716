let RegexParser = require("regex-parser");
import dayjs from 'dayjs';

export const convertStringToRegex = (str: string) => {
    return RegexParser(str)
};

export const getInitials = (str: string = '') => {
    const nameWords = str.split(' ');
    const initials = nameWords.length > 1 ? `${nameWords[0][0]}${nameWords[nameWords.length - 1][0]}` : nameWords[0][0];
    return initials?.toUpperCase();
}

export const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(value);
}

export const formatDate = (date: any, format: string = "") => {
    if (!date) return '';
    if (format) {
        if (format === 'YYYY-MM-DD') {
            return dayjs(date).format('YYYY-MM-DD');
        }
        const options: Intl.DateTimeFormatOptions = {};
        if (format.includes('yyyy') || format.includes('YYYY')) options.year = 'numeric';
        if (format.includes('MMMM')) options.month = 'long';
        else if (format.includes('MMM')) options.month = 'short';
        else if (format.includes('MM')) options.month = '2-digit';
        if (format.includes('dd')) options.day = '2-digit';
        return new Intl.DateTimeFormat('en-IN', options).format(new Date(date));
    }
    return new Intl.DateTimeFormat('en-IN', { year: 'numeric', month: 'short', day: '2-digit' }).format(new Date(date));
}

export const removeUndefinedValues = (obj: any): any => {
    if (Array.isArray(obj)) {
        return obj.map((item: any) => removeUndefinedValues(item));
    } else if (typeof obj === 'object' && obj !== null) {
        const newObj: any = {};
        for (const [key, value] of Object.entries(obj)) {
            if (value !== undefined) {
                newObj[key] = removeUndefinedValues(value);
            }
        }
        return newObj;
    } else {
        return obj;
    }
}


export const filterItemsByMonth = (items: any, selectedDate: any) => {
    const filteredItems = {};
    const month = dayjs(selectedDate).format('MMMM YYYY');
    if (items[month]) {
        filteredItems[month] = items[month];
    }
    return filteredItems;
};

export const formatCamelCaseString = (str: string) => {
    if (str.toUpperCase() === str) return str; // Return if the string is all uppercase
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, (s) => s.toUpperCase());
};