import { useLocation, useRouteMatch } from 'react-router-dom';

function useRoute() {

    const location = useLocation();
    const route: any = useRouteMatch();

    const getQueryParams = (key: string) => {
        const searchParams = new URLSearchParams(location.pathname);
        return searchParams.get(key);
    };

    const getAllQueryParams = () => {
        const params: any = new URLSearchParams(location.search);
        let queryParams: any = {};
        for (var value of params.keys()) {
            queryParams[value] = params.get(value);
        }
        return queryParams;
    };

    const getPageId = () => route.params['pageId'];

    return { pageId: getPageId(), queryParams: getAllQueryParams() };
}

export default useRoute;