import React from 'react';
import useRedirect from '../../hooks/useRedirection';
import { IonIcon } from '@ionic/react';
import styles from './Button.module.css';
import Button from '@mui/joy/Button';

const CustomButton: React.FC<any> = (props: any) => {
    const { redirectTo } = useRedirect();

    const handleClick = () => {
        redirectTo(props);
    }

    return <Button
        onClick={handleClick}
        variant="solid"
        color="primary"
        size="md"
        {...props}
    >
        {props.text}
    </Button>
}
export default CustomButton;
