import { EarningManagerService } from "../services/earning-manager.service";

export default function useEarningManager() {

    const addEarning = async (payload: any, onSuccess: Function, onError: Function) => {
        try {
            let resp = await EarningManagerService.addEarning(payload);
            onSuccess(resp);
        } catch (error) {
            onError(error);
        }
    };

    const updateEarning = async (payload: any, onSuccess: Function, onError: Function) => {
        try {
            let resp = await EarningManagerService.updateEarning(payload);
            onSuccess(resp);
        } catch (error) {
            onError(error);
        }
    }

    const deleteEarning = async (earningId: string, onSuccess: Function, onError: Function) => {
        try {
            let resp = await EarningManagerService.deleteEarning(earningId);
            onSuccess(resp);
        } catch (error) {
            onError(error);
        }
    }

    return { addEarning, updateEarning, deleteEarning };
}