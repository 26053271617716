export default {
    API_URL: '',
    FIREBASE: {
        CONFIG: {
            apiKey: "AIzaSyBEeXRN3gbK0af80xo2nwj3L80-iQDr7Yk",
            authDomain: "wealth-manager-8b70a.firebaseapp.com",
            databaseURL: "https://wealth-manager-8b70a-default-rtdb.asia-southeast1.firebasedatabase.app/",
            projectId: "wealth-manager-8b70a",
            storageBucket: "wealth-manager-8b70a.appspot.com",
            messagingSenderId: "1091948853971",
            appId: "1:1091948853971:web:5c2bebe60d8a8b4fe03226",
            measurementId: "G-LT8407YTFC",
          }
    }
};