import { IonCardSubtitle, IonCardTitle, IonTitle } from '@ionic/react';
import React, { useState, useEffect, useRef } from 'react';

const Dropdown: React.FC<any> = (props) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    if (props?.items?.length === 0) return null;

    return (
        <div className="input-group" style={{ paddingLeft: 5 }} ref={dropdownRef}>
            <button
                className="btn btn-outline-primary dropdown-toggle text-wrap"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded={isDropdownOpen}
                onClick={toggleDropdown}
            >
                {props?.items?.[selectedIndex]?.title}
            </button>
            <ul className={`dropdown-menu dropdown-menu-end ${isDropdownOpen ? 'show' : ''}`}>
                {props?.items?.map((item: any, index: number) => (
                    <li onClick={() => {
                        setSelectedIndex(index);
                        toggleDropdown();
                        props?.onListElementClicked && props?.onListElementClicked(item);
                    }} key={index}>
                        <a className="dropdown-item text-wrap">{item.title}</a></li>
                ))}
            </ul>
        </div>
    );
};

export default Dropdown;
