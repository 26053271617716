import { AssetsManagerService } from "./assets-manager.service";
import { FirbaseDbService } from "./FirebaseDB.service";

export const HeaderService = {

    getHeaderConfig: async (page: string, pathParams: any) => {
        const [
            updatedHeaderConfig,
            // accountTypeDetails
        ]: any = await Promise.allSettled([
            FirbaseDbService.getHeaderConfig(),
            // AssetsManagerService.getAccountType(pathParams.assetType)
        ]);
        // this code is not working. need to check this.
        // switch (updatedHeaderConfig.value[page].title) {
        //     case '{assetType}':
        //         updatedHeaderConfig.value[page].title = accountTypeDetails.value.label;
        //         break;
        //     default:
        //         break;
        // }
        return updatedHeaderConfig.value;
    }

}