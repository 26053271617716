import { IonCardSubtitle, IonCardTitle } from '@ionic/react';
import React from 'react';

const Heading: React.FC<any> = (props: any) => {
    switch (props.type) {
        case 'title':
            return <IonCardTitle {...props}>{props.text}</IonCardTitle>;
        case 'subtitle':
            return <IonCardSubtitle {...props}>{props.text}</IonCardSubtitle>;
        case 'h1':
            return <h1 {...props}>{props.text}</h1>;
        case 'h2':
            return <h2 {...props}>{props.text}</h2>;
        case 'h3':
            return <h3 {...props}>{props.text}</h3>;
        case 'h4':
            return <h4 {...props}>{props.text}</h4>;
        case 'h5':
            return <h5 {...props}>{props.text}</h5>;
        case 'h6':
            return <h6 {...props}>{props.text}</h6>;
        case 'p':
            return <p {...props}>{props.text}</p>;
        default:
            return null;
    }
}
export default Heading;
