import { useHistory } from "react-router-dom";
import { BrowserService } from "../services/Browser.service";
import { ShareService } from "../services/Share.service";
import { removeUndefinedValues } from "../utils/common.util";

export default function useRedirect() {
    let history = useHistory();

    const redirectTo = (item: any, relativeToUrl: any = false, queryParams: any = {}) => {
        let { redirectionUrl = '', webViewUrl = '', params = queryParams, shareObj = null, title = '', subTitle = '' } = item;
        params = removeUndefinedValues(params);
        if (shareObj && shareObj.redirectionUrl) {
            redirectionUrl = shareObj.redirectionUrl;
        }
        if (shareObj && shareObj.webViewUrl) {
            webViewUrl = shareObj.webViewUrl;
        }
        if (!redirectionUrl && !webViewUrl && !shareObj) {
            return;
        }
        if (redirectionUrl == -1) {
            window.history.back();
            return;
        }
        if (shareObj && shareObj.payload) {
            if (shareObj.payload.shareOnWhatsappOnly) {
                if (shareObj.payload.shareAsNews) {
                    window.location.href = `https://api.whatsapp.com/send?text=${`*${title}* %0A ${subTitle} %0A ${shareObj.payload.text} %0A ${shareObj.payload.url}`}`;
                    return;
                }
                window.location.href = `https://api.whatsapp.com/send?text=${shareObj.payload.text} %0A ${shareObj.payload.url}`;
                return;
            }
            ShareService.shareExternally(shareObj.payload);
            return;
        }
        if (webViewUrl) {
            BrowserService.openWebview(webViewUrl);
            return;
        }
        if (redirectionUrl.startsWith('https') || redirectionUrl.startsWith('http') || redirectionUrl.startsWith('mailto')) {
            window.open(redirectionUrl, '_blank');
            return;
        }
        if (params) {
            redirectionUrl += '?' + Object.keys(params).map(k => `${k}=${params[k]}`).join('&');
        }
        if (relativeToUrl) {
            history.replace(redirectionUrl);
        } else {
            history.push(redirectionUrl);
        }
        window.scrollTo({ top: 0, left: 0 });
    };

    return { redirectTo };
}