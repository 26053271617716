import { getRandomColor } from "../utils/styles.util";
import { formatCurrency } from "../utils/common.util";
import { ROUTES } from "../constants/routes.constant";
import { AssetsManagerService } from "../services/assets-manager.service";
import { SpendManagerService } from "../services/spend-manager.service";
import { EarningManagerService } from "../services/earning-manager.service";

export const getDashboardResponse = async (payload: any = { isViewSummary: true, isViewAccounts: true, date: new Date().getTime() }) => {
    const sections = [];

    let [mergedInvestments, mergedSpends, earnings]: [any, any, any] =
        await Promise.all([
            AssetsManagerService.getMergedAssetDetails(payload.date),
            SpendManagerService.getMergedSpendsDetails(payload.date, false),
            EarningManagerService.getMergedEarningsDetails(payload.date)
        ]);

    if (payload.isViewAccounts) {
        const processAccounts = async ({ accounts, accountsCount, label, color, redirectionType }) => {
            if (['INVESTMENT', 'BORROWED', 'LOAN'].indexOf(redirectionType) > -1) {
                sections.push({
                    sectionType: "INFO_CARDS_ROW",
                    items: [
                        {
                            itemType: "CARD",
                            title: `Amount ${label}`,
                            description: formatCurrency(accounts.reduce((sum, account) => sum + account.amount, 0)),
                            description1: `${accountsCount} Account${accountsCount > 1 ? 's' : ''}`,
                            style: {
                                marginTop: 2
                            },
                            highlightedCard: true,
                            highlightedCardAttr: {
                                color
                            },
                            isUpIcon: color === 'primary',
                            isDownIcon: color == 'danger',
                            redirectionUrl: ROUTES.VIEW_ALL_ASSET_DETAILS.path,
                            params: {
                                investmentType: redirectionType
                            }
                        },
                    ],
                });
            }

            sections.push({
                "sectionType": "HEADING",
                "type": "subtitle",
                "text": `${label} Accounts`,
                "style": {
                    "marginTop": 10
                }
            });

            for (let i = 0; i < accounts.length; i += 2) {
                const item1 = accounts[i];
                const item2 = accounts[i + 1];

                const accountType1 = await AssetsManagerService.getAccountType(item1.type);
                const row = {
                    sectionType: "INFO_CARDS_ROW",
                    title: "",
                    items: [
                        {
                            itemType: "CARD",
                            type: item1.type,
                            title: accountType1.label,
                            description: formatCurrency(item1.amount),
                            color: accountType1.color || getRandomColor(),
                            redirectionUrl: `/asset/${item1.type}/view`,
                            cta: "View Details",
                            cardHeadingColor: color,
                            count: item1.count
                        },
                    ],
                };

                if (item2) {
                    const accountType2 = await AssetsManagerService.getAccountType(item2.type);
                    row.items.push({
                        itemType: "CARD",
                        type: item2.type,
                        title: accountType2.label,
                        description: formatCurrency(item2.amount),
                        color: accountType2.color || getRandomColor(),
                        redirectionUrl: `/asset/${item2.type}/view`,
                        cta: "View Details",
                        cardHeadingColor: color,
                        count: item2.count
                    });
                }
                sections.push(row);
            }
        };

        const subTypes = await AssetsManagerService.getAccountSubTypes();

        for (const { key, label, color, redirectionType } of subTypes) {
            const accounts = mergedInvestments[key] || [];
            const accountsCount = mergedInvestments[key + 'Count'];
            if (accounts.length) {
                await processAccounts({ accounts, accountsCount, label, color, redirectionType });
            }
        }


        // No accounts tagged to any sub type - 

        const { noSubTypeInvestments } = mergedInvestments;

        if (noSubTypeInvestments.length) {
            sections.push({
                "sectionType": "HEADING",
                "type": "subtitle",
                "text": `Other Accounts`,
                "style": {
                    "paddingBottom": -10,
                    "marginTop": 5
                }
            });

            for (let i = 0; i < noSubTypeInvestments.length; i += 2) {
                const item1 = noSubTypeInvestments[i];
                const item2 = noSubTypeInvestments[i + 1];

                const accountType1 = await AssetsManagerService.getAccountType(item1.type);
                const row = {
                    sectionType: "INFO_CARDS_ROW",
                    title: "",
                    items: [
                        {
                            itemType: "CARD",
                            type: item1.type,
                            title: accountType1.label,
                            description: formatCurrency(item1.amount),
                            color: accountType1.color || getRandomColor(),
                            redirectionUrl: `/asset/${item1.type}/view`,
                            cta: "View Details",
                            cardHeadingColor: getRandomColor(),
                            count: item1.count
                        },
                    ],
                };

                if (item2) {
                    const accountType2 = await AssetsManagerService.getAccountType(item2.type);
                    row.items.push({
                        itemType: "CARD",
                        type: item2.type,
                        title: accountType2.label,
                        description: formatCurrency(item2.amount),
                        color: accountType2.color || getRandomColor(),
                        redirectionUrl: `/asset/${item2.type}/view`,
                        cta: "View Details",
                        cardHeadingColor: getRandomColor(),
                        count: item2.count
                    });
                }
                sections.push(row);
            }
        }

        if (sections.length) {
            sections.unshift({
                sectionType: "BUTTON",
                text: "Add New Account",
                redirectionUrl: ROUTES.ADD_ASSET.path,
                style: {
                    marginTop: 10,
                    backgroundColor: "rgba(192,172,103)",
                },
                className: "w-100"
            });
        }

    }


    if (payload.isViewSummary) {
        const { investedAmountInCurrentMonth } = mergedInvestments;
        const { totalSpendsAmount } = mergedSpends;
        const { totalEarningsAmount } = earnings;
        const balance = totalEarningsAmount - totalSpendsAmount - investedAmountInCurrentMonth;
        sections.push({
            sectionType: "INFO_CARDS_ROW",
            items: [
                {
                    itemType: "CARD",
                    title: "Balance",
                    cardHeadingColor: "white",
                    description: `${formatCurrency(balance)}`,
                    description1: `Earnings: ${formatCurrency(totalEarningsAmount)}`,
                    description2: "Investments: " + formatCurrency(investedAmountInCurrentMonth),
                    description3: "Spends: " + formatCurrency(totalSpendsAmount),
                    // description4: formatDate(payload.date, 'MMMM YYYY'),
                    color: 'rgb(0 69 128)',
                    style: {
                        marginTop: 2,
                        color: "white",
                        // backgroundColor: balance > 0 ? "rgb(0 69 128)" : balance < 0 ? "rgb(192 55 55)" : "rgb(192 172 103)"
                        backgroundColor: 'black'
                    },
                    cardHeight: 140,
                    highlightedCard: true,
                    highlightedCardAttr: {
                        // color: balance > 0 ? "primary" : balance < 0 ? "danger" : "warning",
                    },
                },
            ],
        });
    };

    return {
        sections
    };
}