import { LOCAL_STORAGE_CONSTANT } from "../constants/localStorage.constant";
import { getAccumulatedAmount } from "../mapper/investment.mapper";
import { getSpendId, mapSpendPayload } from "../mapper/spend.mapper";
import { LocalStorageUtils } from "../utils/localStorage.util";
import { AssetsManagerService } from "./assets-manager.service";
import { CommonService } from "./common.service";
import { FirbaseDbService } from "./FirebaseDB.service";

export const SpendManagerService = {
    // Get spend type object
    getSpendType: async (key: string): Promise<any> => {
        const constantData = await FirbaseDbService.getAllConstants();
        const spendType = constantData?.SPEND_TYPE?.find((spend: any) => spend.value === key);
        return spendType;
    },

    // Get Spend Details
    getMergedSpendsDetails: async (dateTs: number, includesCashTxn = true) => {
        return new Promise(async (resolve, reject) => {
            try {
                let spends = await CommonService.getRawData('spends');
                const spendsInCash = Object.values(spends).filter((spend: any) => spend.type === 'cash');
                if (!includesCashTxn) {
                    spends = Object.values(spends).filter((spend: any) => spend.type !== 'cash');
                }
                if (!dateTs) {
                    resolve({
                        spendsTransactions: Object.values(spends),
                        totalSpendsAmount: getAccumulatedAmount(Object.values(spends))
                    });
                    return;
                }
                let filteredSpends = Object.values(spends).filter((earning: any) => {
                    let earningDate = new Date(earning.date);
                    return earningDate.getMonth() === new Date(dateTs).getMonth() &&
                        earningDate.getFullYear() === new Date(dateTs).getFullYear();
                });
                resolve({
                    spendsTransactions: filteredSpends,
                    totalSpendsAmount: getAccumulatedAmount(filteredSpends),
                    spendsInCash
                });
            } catch (error) {
                reject(error);
            }
        });
    },

    // Add Spend
    addSpend: (payload: any = {}) => {
        return new Promise(async (resolve, reject) => {
            let spends: any = await CommonService.getRawData('spends');
            const spendId = getSpendId(payload);
            let _payload = {
                id: spendId,
                ...mapSpendPayload(payload),
                createdAt: new Date().getTime()
            }
            spends[spendId] = _payload;
            try {
                await FirbaseDbService.addDataInFirestoreDb(
                    'users',
                    LocalStorageUtils.getValueFromLocalStorage(LOCAL_STORAGE_CONSTANT.UID),
                    { ...await CommonService.getRawData(), spends }
                );
                if (payload.transactionMadeFrom !== 'none') {
                    payload.investmentId = payload.transactionMadeFrom;
                    payload.amount = -payload.amount;
                    payload.note = `Expense: ${payload.type} ${payload.customerName ? `by ${payload.customerName}` : ''} ${payload.note ? `(${payload.note})` : ''}`;
                    AssetsManagerService.addInvestment(payload);
                }
                resolve({ message: 'Expense added successfully', id: spendId });
            } catch (error) {
                reject(error);
            }
        });
    },

    // Update Spend
    updateSpend: (payload: any = {}) => {
        return new Promise(async (resolve, reject) => {
            let spends: any = await CommonService.getRawData('spends');
            let _payload = {
                ...mapSpendPayload(payload),
                updatedAt: new Date().getTime()
            }
            spends[payload.id] = _payload;
            try {
                await FirbaseDbService.updateDataInFirestoreDb(
                    'users',
                    LocalStorageUtils.getValueFromLocalStorage(LOCAL_STORAGE_CONSTANT.UID),
                    { ...await CommonService.getRawData(), spends }
                );
                resolve({ message: 'Expense updated successfully' });
            } catch (error) {
                reject(error);
            }
        });
    },

    // Delete Spend
    deleteSpend: (spendId: string) => {
        return new Promise(async (resolve, reject) => {
            let spends: any = await CommonService.getRawData('spends');
            delete spends[spendId];
            try {
                await FirbaseDbService.addDataInFirestoreDb(
                    'users',
                    LocalStorageUtils.getValueFromLocalStorage(LOCAL_STORAGE_CONSTANT.UID),
                    { ...await CommonService.getRawData(), spends }
                );
                resolve({ message: 'Expense deleted successfully' });
            } catch (error) {
                reject(error);
            }
        });
    }

}