import { IonHeader, IonToolbar, IonRow, IonCol, IonIcon, IonTitle } from '@ionic/react';
import { chevronBack, shareSocial } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router";
import useRedirect from '../../hooks/useRedirection';
import { FIREBASE_DB_CONSTANTS } from '../../constants/firebase.constant';
import { LocalStorageUtils } from '../../utils/localStorage.util';
import { HeaderService } from '../../services/header.service';
import './Header.css';

const Header: React.FC<any> = ({ page }) => {
    try {
        const { redirectTo } = useRedirect();
        const pathParams = useParams<any>();
        const [headerConfig, setHeaderConfig] = useState<any>((page && JSON.parse(LocalStorageUtils.getValueFromLocalStorage(FIREBASE_DB_CONSTANTS.HEADER_CONFIG.dbpath))?.[page]) || {});

        useEffect(() => {
            const getUpdatedConfig = async () => {
                const updatedHeaderConfig = await HeaderService.getHeaderConfig(page, pathParams);
                setHeaderConfig(updatedHeaderConfig?.[page]);
            };

            getUpdatedConfig();
        });

        if (!headerConfig) return null;

        return (
            <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonToolbar placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    {!headerConfig?.hideRow && <IonRow style={{ background: headerConfig?.bgColor }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <IonCol size={headerConfig?.leftSectionSize || '1'} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            {headerConfig?.backBtnObj &&
                                <IonIcon style={{ fontSize: 20 }} onClick={() => redirectTo(headerConfig?.backBtnObj)} icon={chevronBack} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                            }
                        </IonCol>
                        {(headerConfig?.imageUrl || headerConfig?.title) && <IonCol className={headerConfig?.imageTitleClass} size={headerConfig?.centerSectionSize || '9'} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            {headerConfig?.imageUrl && <img loading='lazy' src={headerConfig?.imageUrl} height={50} />}
                            {headerConfig?.title && headerConfig?.title}
                        </IonCol>}
                        {(headerConfig?.shareObj) &&
                            <IonCol size={headerConfig?.rightSectionSize || (headerConfig?.backBtnObj ? '2' : '3')} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                <IonIcon onClick={() => redirectTo(headerConfig)} style={headerConfig?.shareObj?.style} icon={shareSocial} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                            </IonCol>
                        }
                    </IonRow>}

                    {headerConfig?.showBigTitle ?
                        <IonRow placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            {(headerConfig?.imageUrl || headerConfig?.title) && <IonCol className={headerConfig?.imageTitleClass} size={headerConfig?.centerSectionSize || '12'} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                {headerConfig?.imageUrl && <img loading='lazy' src={headerConfig?.imageUrl} height={50} />}
                                {/* <h6>{headerConfig?.title && headerConfig?.title}</h6> */}
                            </IonCol>}
                            <IonTitle placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{headerConfig?.title}</IonTitle>
                            {/* {headerConfig?.backBtnObj &&
                                <IonCol size={headerConfig?.leftSectionSize || '1'}>
                                    <IonIcon style={{ fontSize: 26 }} onClick={() => redirectTo(headerConfig?.backBtnObj)} icon={chevronBack} />
                                </IonCol>
                            }
                            {headerConfig?.title && <IonCol className={`${headerConfig?.imageTitleClass}`} size={headerConfig?.centerSectionSize || '11'}>
                                <IonTitle>{headerConfig?.title}</IonTitle>
                            </IonCol>} */}
                        </IonRow>
                        : ''}
                </IonToolbar>
            </IonHeader>
        );
    } catch (error) {
        return null;
    }
}
export default Header;
