import { ENV } from '../constants/env.constants';
import { default as localBaseUrls } from './config.local';
import { default as prodBaseUrls } from './config.prod';

let CONFIG: any;
if (process.env.REACT_APP_ENV == ENV.PRODUCTION) {
    CONFIG = prodBaseUrls;
} else {
    CONFIG = localBaseUrls;
}

const { API_URL, FIREBASE } = CONFIG;

export const ENV_CONFIG = {
    FIREBASE,
    GET_INITIAL_DATA: `${API_URL}/dashboard/initial`,
    GET_DASHBOARD_DATA: `${API_URL}/dashboard`,
    LOGIN_OR_REGISTER_USER: `${API_URL}/user/loginOrRegister/{0}`,
    GET_USER_DETAILS: `${API_URL}/user/details`,
    UPDATE_USER_DETAILS: `${API_URL}/user/update?`,
    SEND_OTP: `${API_URL}/user/sendOtp`,
    GET_CONFIG: `${API_URL}/config/page/{0}`,
};