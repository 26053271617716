
import { IonSpinner } from '@ionic/react';
import styles from './Loader.module.css';

const Loader: React.FC = () => {

    return (
        <>
            <div className={`${styles.overlay} hide`} id='loader'>
                <div className='body-center'>
                    <IonSpinner name="circular" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonSpinner>
                </div>
            </div>
            <div id="loader-overlay" className="loader-overlay"></div>
        </>
    )
}
export default Loader;
