import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { useEffect, useState } from "react";

export default function useAppDetails() {
    const [appDetails, setAppDetails] = useState(<any>{});

    useEffect(() => {
        (async () => {
            App.getInfo().then((appDetails: any) => {
                setAppDetails(appDetails);
            });
        })();
    }, []);

    const getAppVersion = () => {
        return appDetails.version || '1.0.0';
    };

    const getBuildNumber = () => {
        return appDetails.build || '11';
    };

    const getPlatform = () => {
        return Capacitor.getPlatform()
    };

    return { appVersion: getAppVersion(), buildNumber: getBuildNumber(), platform: getPlatform() };
}