import { useEffect } from 'react';
import { Keyboard } from '@capacitor/keyboard';

const KeyboardWrapper: React.FC<any> = (props: any): any => {
    const { children } = props;

    useEffect(() => {
        // Add event listeners to show/hide keyboard
        const showKeyboardListener = Keyboard.addListener('keyboardDidShow', () => {
            props.handleKeyboardOpen(true);
            // Add logic to adjust UI when keyboard is shown
        });

        const hideKeyboardListener = Keyboard.addListener('keyboardDidHide', () => {
            props.handleKeyboardOpen(false);
            // Add logic to reset UI when keyboard is hidden
        });

        // Clean up event listeners on component unmount
        return () => {
            showKeyboardListener.remove();
            hideKeyboardListener.remove();
        };
    }, []); // Run effect only once on component mount

    return (
        <>
            {children}
        </>
    );
};

export default KeyboardWrapper;
