import React, { useState, useEffect } from 'react';
import { IonToast } from '@ionic/react';
import { toastService } from '../../services/toast.service';

const Toast: React.FC = () => {
    const [showToast, setShowToast] = useState(false);
    const [message, setMessage] = useState('');
    const [color, setColor] = useState('');
    const [position, setPosition] = useState<'top' | 'bottom' | 'middle' | undefined>('top');
    const [duration, setDuration] = useState(3000);

    useEffect(() => {
        const handleShowToast = ({ message, duration = 3000, color, position = "top" }: any) => {
            setMessage(message);
            setDuration(duration);
            setColor(color);
            setPosition(position);
            setShowToast(true);
            setTimeout(() => setShowToast(false), duration);
        };

        toastService.eventEmitter.on('showToast', handleShowToast);

        return () => {
            toastService.eventEmitter.off('showToast', handleShowToast);
        };
    }, []);

    return (
        <IonToast
            color={color}
            position={position}
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={message}
            duration={duration}
            cssClass = "toast-cls"
            htmlAttributes={{
                zIndex: 9999
            }} // Set the z-index to a high value
        />
    );
};

export default Toast;