import { ROUTES } from "../constants/routes.constant";
import { AssetsManagerService } from "../services/assets-manager.service";
import { formatCamelCaseString, formatCurrency, formatDate } from "../utils/common.util";

export const mapInvestmentPayload = (payload: any) => {
    return {
        type: payload.type,
        bankName: payload.bankName,
        customerName: payload.customerName,
        accountNumber: payload.accountNumber,
        dueDate: payload.dueDate,
        subType: payload.subType,
        paymentFrequency: payload.paymentFrequency,
    };
}

export const mapInvestmentTransaction = (payload: any) => {
    return {
        isSynced: payload.isSynced || false,
        amount: +payload.amount,
        date: payload.date,
        note: payload.note
    }
}

export const mapInvestmentItems = async (investments: any[]) => {
    let paymentFrequencies = await AssetsManagerService.getPaymentFrequencyTypes();
    const _investments = investments.map((investment: any) => {
        const periodStr = paymentFrequencies.find((frequency: any) => frequency.value === investment.paymentFrequency)?.period || '';
        return {
            frequencyStr: (investment.paymentFrequency && investment.paymentFrequency !== 'oneTime') ? `${formatCurrency(investment?.transactions?.[0]?.amount)} ${periodStr}` : '',
            ...investment,
            isUpIcon: ['INVESTMENT'].indexOf(investment.subType) > -1,
            isDownIcon: ['BORROWED', 'LOAN'].indexOf(investment.subType) > -1,
            amount: getAccumulatedAmount(investment.transactions),
            transactionsList: investment.transactions.map((transaction: any) => {
                return {
                    ...transaction,
                    label: formatDate(transaction.date),
                    value: formatCurrency(transaction.amount),
                    description: transaction.note,
                    cta1: {
                        className: 'btn btn-sm btn-warning',
                        style: {
                            marginLeft: 10
                        },
                        ctaText: 'Edit',
                        redirectionUrl: ROUTES.UPDATE_TRANSACTION.path,
                        queryParams: {
                            ...investment,
                            ...transaction,
                            assetId: investment.id,
                            transactionId: transaction.id,
                            transactions: []
                        }
                    },
                    cta2: {
                        className: 'btn btn-sm btn-danger',
                        style: {
                            marginLeft: 10
                        },
                        ctaText: 'Delete',
                        isDelete: true
                    }
                }
            }).sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime()),
            items: [
                { label: 'Account Number', value: investment.accountNumber, className: 'col-6' },
                { label: 'Customer Name', value: investment.customerName, className: 'col-6' },
                { label: 'Total Amount', value: formatCurrency(getAccumulatedAmount(investment.transactions)), className: 'col-6' },
                { label: 'Maturity Date', value: investment.dueDate ? formatDate(investment.dueDate) : 'NA', className: 'col-6' },
                { label: 'Frequency', value: investment.paymentFrequency ? formatCamelCaseString(investment.paymentFrequency) : 'NA', className: 'col-6' },
            ],
            cta1: {
                className: 'btn btn-sm btn-outline-primary',
                ctaText: 'View Details',
                data: investment,
                redirectionUrl: `/asset/${investment.type}/txndetails/${investment.id}`,
            },
            cta2: {
                className: 'btn btn-sm btn-danger',
                style: {
                    marginLeft: 10
                },
                ctaText: 'Delete',
                isDelete: true
            }
        };
    }).sort((a: any, b: any) => a.customerName.localeCompare(b.customerName));

    paymentFrequencies = paymentFrequencies.map((frequency: any) => frequency.value);
    const paymentToBeMade = {}
    const currentYear = new Date().getFullYear();

    paymentFrequencies.forEach(frequency => {
        const filteredInvestments = _investments.filter(investment => investment.paymentFrequency === frequency);
        let paidTillNow = 0;
        let willBePaid = 0;

        filteredInvestments.forEach(investment => {
            const dueDate = investment.dueDate ? new Date(investment.dueDate) : new Date(new Date().setFullYear(new Date().getFullYear() + 1));
            const firstTransaction = investment.transactions[0];
            const amount = firstTransaction ? +firstTransaction.amount : 0;

            if (dueDate.getFullYear() >= currentYear) {
                if (investment.transactions.some(transaction => new Date(transaction.date).getFullYear() === currentYear)) {
                    paidTillNow += amount;
                } else {
                    willBePaid += amount;
                }
            }
        });

        if ((paidTillNow > 0 || willBePaid > 0) && (frequency !== 'oneTime' && frequency !== 'daily')) {
            paymentToBeMade[frequency] = {
                paidTillNow,
                willBePaid
            };
        }
    });

    return {
        investments: _investments,
        paymentToBeMade
    };
}

export const getAccumulatedAmount = (transactions: any[]) => {
    return transactions.reduce((acc: any, transaction: any) => {
        return acc + +transaction.amount;
    }, 0);
}

export const getInvestmentId = (payload: any = {}) => {
    return `${payload.type}_${payload.accountNumber}_${payload.bankName.toLowerCase().replace(/ /g, '_')}`;
};