import { CACHE_CONSTANT } from "../constants/cache.constants";

let obj: any = {};

export const CacheService = {

    set: (key: string | number, value: any) => {
        obj[key] = value;
    },

    get: (key: string | number) => {
        return obj[key];
    },

    resetTs: () => {
        CacheService.set(CACHE_CONSTANT.API_TS, new Date().getTime());
    }

};