import { LOCAL_STORAGE_CONSTANT } from "../constants/localStorage.constant";
import { getAccumulatedAmount } from "../mapper/investment.mapper";
import { getEarningId, mapEarningPayload } from "../mapper/earning.mapper";
import { LocalStorageUtils } from "../utils/localStorage.util";
import { FirbaseDbService } from "./FirebaseDB.service";
import { CommonService } from "./common.service";

export const EarningManagerService = {
    // Get Earning Details
    getMergedEarningsDetails: async (dateTs: number) => {
        return new Promise(async (resolve, reject) => {
            try {
                let earnings = await CommonService.getRawData('earnings');
                if (!dateTs) {
                    resolve({
                        earningsTransactions: Object.values(earnings),
                        totalEarningsAmount: getAccumulatedAmount(Object.values(earnings))
                    });
                    return;
                }
                let filteredEarnings = Object.values(earnings).filter((earning: any) => {
                    let earningDate = new Date(earning.date);
                    return earningDate.getMonth() === new Date(dateTs).getMonth() &&
                        earningDate.getFullYear() === new Date(dateTs).getFullYear();
                });
                resolve({
                    earningsTransactions: filteredEarnings,
                    totalEarningsAmount: getAccumulatedAmount(filteredEarnings)
                });
            } catch (error) {
                reject(error);
            }
        });
    },

    // Add Earning
    addEarning: (payload: any = {}) => {
        return new Promise(async (resolve, reject) => {
            let earnings: any = await CommonService.getRawData('earnings');
            const earningId = getEarningId(payload);
            let _payload = {
                id: earningId,
                ...mapEarningPayload(payload),
                createdAt: new Date().getTime(),
            }
            earnings[earningId] = _payload;
            try {
                await FirbaseDbService.addDataInFirestoreDb(
                    'users',
                    LocalStorageUtils.getValueFromLocalStorage(LOCAL_STORAGE_CONSTANT.UID),
                    { ...await CommonService.getRawData(), earnings }
                );
                resolve({ message: 'Earning added successfully', id: earningId });
            } catch (error) {
                reject(error);
            }
        });
    },

    // Update Earning
    updateEarning: (payload: any = {}) => {
        return new Promise(async (resolve, reject) => {
            let earnings: any = await CommonService.getRawData('earnings');
            let _payload = {
                ...mapEarningPayload(payload),
                updatedAt: new Date().getTime(),
            }
            earnings[payload.id] = _payload;
            try {
                await FirbaseDbService.updateDataInFirestoreDb(
                    'users',
                    LocalStorageUtils.getValueFromLocalStorage(LOCAL_STORAGE_CONSTANT.UID),
                    { ...await CommonService.getRawData(), earnings }
                );
                resolve({ message: 'Earning updated successfully' });
            } catch (error) {
                reject(error);
            };
        });
    },

    // Delete Earning
    deleteEarning: (earningId: string) => {
        return new Promise(async (resolve, reject) => {
            let earnings: any = await CommonService.getRawData('earnings');
            delete earnings[earningId];
            try {
                await FirbaseDbService.addDataInFirestoreDb(
                    'users',
                    LocalStorageUtils.getValueFromLocalStorage(LOCAL_STORAGE_CONSTANT.UID),
                    { ...await CommonService.getRawData(), earnings }
                );
                resolve({ message: 'Earning deleted successfully' });
            } catch (error) {
                reject(error);
            }
        });
    }

}