import axios from 'axios';
import { toastService } from './toast.service';
import { LocalStorageUtils } from '../utils/localStorage.util';
import { LOCAL_STORAGE_CONSTANT } from '../constants/localStorage.constant';
import { ROUTES } from '../constants/routes.constant';
import { LoaderService } from './loader.service';
import { FirebaseAuthService } from './FirebaseAuth.service';
import { CacheService } from './cache.service';
import { CACHE_CONSTANT } from '../constants/cache.constants';

const ts = new Date().getTime();

axios.interceptors.request.use(
    (options) => {
        options.params = {
            ts: CacheService.get(CACHE_CONSTANT.API_TS) || ts
        };
        return options;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export function serviceCall(url: string, method: string = 'GET', options: any = {}) {
    options.method = method;
    return new Promise(async (resolve, reject) => {
        let retries = 0;
        const maxRetries = 0;
        if (method !== 'GET' || options.loader) {
            LoaderService.show();
            CacheService.resetTs();
        }
        while (retries <= maxRetries) {
            try {
                axios.defaults.headers.common['sso'] = LocalStorageUtils.getValueFromLocalStorage(LOCAL_STORAGE_CONSTANT.SSO);
                options.timeout = 1000 * 60; // 60 seconds
                const response = await axios(url, options);
                resolve(response);
                LoaderService.hide();
                break;
            } catch (err: any) {
                if (err.response && err.response.status === 401) {
                    if (await FirebaseAuthService.initAuthStateListener()) {
                        window.location.reload();
                        LoaderService.hide();
                        return;
                    }
                    toastService.showErrorToast({ message: "Invalid user. Login again to proceed." });
                    // will have to uncomment
                    LocalStorageUtils.logout();
                    window.location.href = ROUTES.LOGIN.path;
                    break;
                }
                if (retries == maxRetries) {
                    toastService.showErrorToast({ message: err?.response?.data?.error?.message || err.message });
                    reject(err);
                    LoaderService.hide();
                    break;
                }
                retries++;
            }
        }
    });
}