import { IonApp, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import './App.css';
import { StatusBar, Style } from '@capacitor/status-bar';
// import './services/FirebaseAdmin.service';
import { BackBtnHandler } from './BackBtnHandler';
import PushNotificationsWrapper from './components/PushNotification/PushNotification';
import RootWrapper from './RootWrapper';
import './Firebase';
import { Capacitor } from '@capacitor/core';

setupIonicReact();

const App: React.FC = () => {

  if (Capacitor.isNativePlatform()) {
    BackBtnHandler.init();
    // let status bar overlay webview
    StatusBar.setOverlaysWebView({ overlay: true });

    // set status bar to white
    StatusBar.setStyle({ style: Style.Light });
  }


  // useEffect(() => {

  //   var data = {
  //     "spends": {
  //       "others_1727499417773": {
  //         "createdAt": 1727499417773,
  //         "date": "2024-09-28",
  //         "amount": "1000",
  //         "customerName": "Sashi plumber",
  //         "type": "others",
  //         "id": "others_1727499417773",
  //         "updatedAt": 1727589627772
  //       },
  //       "others_1727280971054": {
  //         "id": "others_1727280971054",
  //         "date": "2024-09-24",
  //         "updatedAt": 1727502692596,
  //         "createdAt": 1727280971054,
  //         "amount": "2000",
  //         "type": "others",
  //         "customerName": "Jaula coupon",
  //         "note": ""
  //       },
  //       "others_1727589587063": {
  //         "type": "others",
  //         "amount": "1915",
  //         "createdAt": 1727589587063,
  //         "transactionMadeFrom": "savingAccount_3145587825_kotak_bank",
  //         "customerName": "Sashi plumber ka saman",
  //         "id": "others_1727589587063",
  //         "date": "2024-09-28"
  //       }
  //     },
  //     "investments": {
  //       "savingAccount_XXXX5721_post_office": {
  //         "isInvestment": true,
  //         "type": "savingAccount",
  //         "customerName": "Preeti Jain",
  //         "id": "savingAccount_XXXX5721_post_office",
  //         "transactions": [
  //           {
  //             "amount": 526000,
  //             "date": "2024-09-23",
  //             "id": 1
  //           }
  //         ],
  //         "bankName": "Post Office",
  //         "accountNumber": "XXXX5721"
  //       },
  //       "savingAccount_307602130000260_union_bank": {
  //         "bankName": "Union Bank",
  //         "accountNumber": "307602130000260",
  //         "type": "savingAccount",
  //         "id": "savingAccount_307602130000260_union_bank",
  //         "customerName": "Aman Jain",
  //         "isInvestment": true,
  //         "transactions": [
  //           {
  //             "id": 1,
  //             "amount": 520.5,
  //             "date": "2024-09-23"
  //           }
  //         ]
  //       },
  //       "lic_Avyansh Jain_lic": {
  //         "customerName": "Avyansh Jain",
  //         "transactions": [
  //           {
  //             "amount": 100000,
  //             "date": "2024-09-23",
  //             "id": 1
  //           }
  //         ],
  //         "id": "lic_Avyansh Jain_lic",
  //         "bankName": "LIC",
  //         "accountNumber": "169331225",
  //         "type": "lic",
  //         "isInvestment": true
  //       },
  //       "crypto_CS Kuber_cs_kuber": {
  //         "type": "crypto",
  //         "isInvestment": true,
  //         "bankName": "CS Kuber",
  //         "id": "crypto_CS Kuber_cs_kuber",
  //         "transactions": [
  //           {
  //             "id": 1,
  //             "date": "2024-09-23",
  //             "amount": 500
  //           }
  //         ],
  //         "customerName": "Aman Jain",
  //         "accountNumber": "CS Kuber"
  //       },
  //       "fd_3145587825_kotak_bank": {
  //         "bankName": "Kotak Bank",
  //         "transactions": [
  //           {
  //             "date": "2024-09-23",
  //             "id": 1,
  //             "amount": 339907
  //           }
  //         ],
  //         "isInvestment": true,
  //         "accountNumber": "3145587825",
  //         "id": "fd_3145587825_kotak_bank",
  //         "type": "fd",
  //         "customerName": "Preeti Jain"
  //       },
  //       "mutualFund_UTI Flexi Fund MF_uti_app": {
  //         "id": "mutualFund_UTI Flexi Fund MF_uti_app",
  //         "isInvestment": true,
  //         "customerName": "Aman Jain",
  //         "bankName": "UTI App",
  //         "accountNumber": "UTI Flexi Fund MF",
  //         "type": "mutualFund",
  //         "transactions": [
  //           {
  //             "date": "2024-09-23",
  //             "amount": 70000,
  //             "id": 1
  //           }
  //         ]
  //       },
  //       "lic_144960448_lic": {
  //         "id": "lic_144960448_lic",
  //         "isInvestment": true,
  //         "bankName": "LIC",
  //         "transactions": [
  //           {
  //             "note": "",
  //             "amount": 393220,
  //             "date": "2024-09-23",
  //             "id": 1
  //           }
  //         ],
  //         "customerName": "Aman Jain",
  //         "accountNumber": "144960448",
  //         "type": "lic",
  //         "dueDate": "2024-09-28"
  //       },
  //       "gold_NA_paytm": {
  //         "id": "gold_NA_paytm",
  //         "bankName": "Paytm",
  //         "accountNumber": "NA",
  //         "isInvestment": true,
  //         "transactions": [
  //           {
  //             "id": 1,
  //             "amount": 1000,
  //             "date": "2024-09-23"
  //           }
  //         ],
  //         "type": "gold",
  //         "customerName": "Aman Jain"
  //       },
  //       "savingAccount_50100629764100_hdfc_bank": {
  //         "customerName": "Aman Jain",
  //         "bankName": "HDFC Bank",
  //         "id": "savingAccount_50100629764100_hdfc_bank",
  //         "isInvestment": true,
  //         "transactions": [
  //           {
  //             "date": "2024-09-23",
  //             "note": "Closed",
  //             "amount": 0,
  //             "id": 1
  //           }
  //         ],
  //         "type": "savingAccount",
  //         "accountNumber": "50100629764100"
  //       },
  //       "pf_100739887998_epf": {
  //         "id": "pf_100739887998_epf",
  //         "isInvestment": true,
  //         "customerName": "Aman Jain",
  //         "type": "pf",
  //         "accountNumber": "100739887998",
  //         "transactions": [
  //           {
  //             "amount": 366416,
  //             "date": "2024-09-23",
  //             "id": 1
  //           }
  //         ],
  //         "bankName": "EPF"
  //       },
  //       "lic_144960306_lic": {
  //         "accountNumber": "144960306",
  //         "customerName": "Aman Jain",
  //         "isInvestment": true,
  //         "type": "lic",
  //         "bankName": "LIC",
  //         "id": "lic_144960306_lic",
  //         "transactions": [
  //           {
  //             "amount": 197860,
  //             "id": 1,
  //             "date": "2024-09-23"
  //           }
  //         ]
  //       },
  //       "savingAccount_02050110027073_uco_bank": {
  //         "isInvestment": true,
  //         "accountNumber": "02050110027073",
  //         "id": "savingAccount_02050110027073_uco_bank",
  //         "type": "savingAccount",
  //         "bankName": "UCO Bank",
  //         "customerName": "Aman Jain",
  //         "transactions": [
  //           {
  //             "date": "2024-09-30",
  //             "amount": 123574.14,
  //             "id": 1
  //           }
  //         ]
  //       },
  //       "lic_159846440_lic": {
  //         "id": "lic_159846440_lic",
  //         "accountNumber": "159846440",
  //         "isInvestment": true,
  //         "transactions": [
  //           {
  //             "amount": 7808,
  //             "id": 1,
  //             "date": "2023-02-28"
  //           },
  //           {
  //             "id": 2,
  //             "date": "2024-02-28",
  //             "amount": 7808
  //           }
  //         ],
  //         "type": "lic",
  //         "customerName": "Preeti Jain",
  //         "bankName": "LIC"
  //       },
  //       "mutualFund_SBI PSU Fund_paytm_money": {
  //         "accountNumber": "SBI PSU Fund",
  //         "type": "mutualFund",
  //         "bankName": "Paytm Money",
  //         "id": "mutualFund_SBI PSU Fund_paytm_money",
  //         "customerName": "Aman Jain",
  //         "isInvestment": true,
  //         "transactions": [
  //           {
  //             "id": 1,
  //             "date": "2024-09-23",
  //             "amount": 74852
  //           }
  //         ]
  //       },
  //       "stock_919810184964_paytm_money": {
  //         "transactions": [
  //           {
  //             "date": "2024-09-23",
  //             "id": 1,
  //             "amount": 117000
  //           }
  //         ],
  //         "id": "stock_919810184964_paytm_money",
  //         "accountNumber": "919810184964",
  //         "customerName": "Preeti Jain",
  //         "type": "stock",
  //         "bankName": "Paytm Money",
  //         "isInvestment": true
  //       },
  //       "mutualFund_UTI Nifty 50 MF_paytm_money": {
  //         "bankName": "Paytm Money",
  //         "transactions": [
  //           {
  //             "amount": 279000,
  //             "id": 1,
  //             "date": "2024-09-23"
  //           }
  //         ],
  //         "id": "mutualFund_UTI Nifty 50 MF_paytm_money",
  //         "accountNumber": "UTI Nifty 50 MF",
  //         "type": "mutualFund",
  //         "isInvestment": true,
  //         "customerName": "Aman Jain"
  //       },
  //       "lic_119105640_lic": {
  //         "bankName": "LIC",
  //         "customerName": "Aman Jain",
  //         "accountNumber": "119105640",
  //         "type": "lic",
  //         "transactions": [
  //           {
  //             "id": 1,
  //             "date": "2024-09-23",
  //             "amount": 76840
  //           }
  //         ],
  //         "id": "lic_119105640_lic",
  //         "isInvestment": true
  //       },
  //       "stock_918800838322_paytm_money": {
  //         "bankName": "Paytm Money",
  //         "accountNumber": "918800838322",
  //         "transactions": [
  //           {
  //             "date": "2024-09-23",
  //             "amount": 478008,
  //             "id": 1
  //           }
  //         ],
  //         "customerName": "Aman Jain",
  //         "isInvestment": true,
  //         "type": "stock",
  //         "id": "stock_918800838322_paytm_money"
  //       },
  //       "fd_020095550702_post_office": {
  //         "isInvestment": true,
  //         "bankName": "Post Office",
  //         "transactions": [
  //           {
  //             "date": "2023-12-15",
  //             "amount": 200000,
  //             "id": 1
  //           }
  //         ],
  //         "id": "fd_020095550702_post_office",
  //         "accountNumber": "020095550702",
  //         "type": "fd",
  //         "customerName": "Aman jain"
  //       },
  //       "mutualFund_Quant Small Cap MF_paytm_money": {
  //         "accountNumber": "Quant Small Cap MF",
  //         "transactions": [
  //           {
  //             "amount": 114000,
  //             "date": "2024-09-23",
  //             "id": 1
  //           }
  //         ],
  //         "isInvestment": true,
  //         "id": "mutualFund_Quant Small Cap MF_paytm_money",
  //         "type": "mutualFund",
  //         "bankName": "Paytm Money",
  //         "customerName": "Aman Jain"
  //       },
  //       "savingAccount_3145587825_kotak_bank": {
  //         "isInvestment": true,
  //         "accountNumber": "3145587825",
  //         "transactions": [
  //           {
  //             "id": 1,
  //             "amount": 1000,
  //             "date": "2024-09-23"
  //           },
  //           {
  //             "note": "Expense: others by Sashi plumber ka saman ",
  //             "id": 2,
  //             "amount": -1915,
  //             "date": "2024-09-28"
  //           }
  //         ],
  //         "bankName": "Kotak Bank",
  //         "id": "savingAccount_3145587825_kotak_bank",
  //         "type": "savingAccount",
  //         "customerName": "Preeti Jain"
  //       },
  //       "lic_146943271_lic": {
  //         "isInvestment": true,
  //         "id": "lic_146943271_lic",
  //         "transactions": [
  //           {
  //             "date": "2024-09-23",
  //             "amount": 80000,
  //             "id": 1
  //           }
  //         ],
  //         "accountNumber": "146943271",
  //         "bankName": "LIC",
  //         "customerName": "Aman Jain",
  //         "type": "lic"
  //       },
  //       "carLoan_002516520000107_union_bank": {
  //         "customerName": "Aman Jain",
  //         "isInvestment": false,
  //         "transactions": [
  //           {
  //             "note": "Kia Sonet Car Loan",
  //             "id": 1,
  //             "amount": 600000,
  //             "date": "2024-06-12"
  //           },
  //           {
  //             "date": "2024-09-10",
  //             "amount": -44564,
  //             "id": 2
  //           }
  //         ],
  //         "subType": "LOAN",
  //         "type": "carLoan",
  //         "bankName": "Union Bank",
  //         "accountNumber": "002516520000107",
  //         "id": "carLoan_002516520000107_union_bank"
  //       },
  //       "mutualFund_Nippon Small Cap MF_paytm_money": {
  //         "accountNumber": "Nippon Small Cap MF",
  //         "id": "mutualFund_Nippon Small Cap MF_paytm_money",
  //         "bankName": "Paytm Money",
  //         "isInvestment": true,
  //         "customerName": "Aman Jain",
  //         "type": "mutualFund",
  //         "transactions": [
  //           {
  //             "id": 1,
  //             "amount": 154000,
  //             "date": "2024-09-23"
  //           }
  //         ]
  //       },
  //       "savingAccount_918800838322_paytm_bank": {
  //         "type": "savingAccount",
  //         "isInvestment": true,
  //         "transactions": [
  //           {
  //             "note": "Closed",
  //             "date": "2024-09-23",
  //             "amount": 0,
  //             "id": 1
  //           }
  //         ],
  //         "bankName": "Paytm Bank",
  //         "customerName": "Aman Jain",
  //         "id": "savingAccount_918800838322_paytm_bank",
  //         "accountNumber": "918800838322"
  //       },
  //       "lic_167336447_lic": {
  //         "bankName": "LIC",
  //         "customerName": "Preeti Jain",
  //         "isInvestment": true,
  //         "transactions": [
  //           {
  //             "date": "2024-09-23",
  //             "id": 1,
  //             "amount": 88750
  //           }
  //         ],
  //         "type": "lic",
  //         "id": "lic_167336447_lic",
  //         "accountNumber": "167336447"
  //       },
  //       "lic_159846420_lic": {
  //         "isInvestment": true,
  //         "customerName": "Aman Jain",
  //         "transactions": [
  //           {
  //             "date": "2023-02-28",
  //             "id": 1,
  //             "amount": 7823
  //           },
  //           {
  //             "amount": 7823,
  //             "id": 2,
  //             "date": "2024-02-28"
  //           }
  //         ],
  //         "accountNumber": "159846420",
  //         "bankName": "LIC",
  //         "id": "lic_159846420_lic",
  //         "type": "lic"
  //       },
  //       "goldBond_Gold Bonds_kotak_bank": {
  //         "bankName": "Kotak Bank",
  //         "id": "goldBond_Gold Bonds_kotak_bank",
  //         "customerName": "Aman Jain",
  //         "isInvestment": true,
  //         "transactions": [
  //           {
  //             "date": "2024-09-23",
  //             "id": 1,
  //             "amount": 58112,
  //             "note": "12 Bonds"
  //           }
  //         ],
  //         "type": "goldBond",
  //         "accountNumber": "Gold Bonds"
  //       },
  //       "savingAccount_xxxx_post_office": {
  //         "customerName": "Aman Jain",
  //         "bankName": "Post Office",
  //         "id": "savingAccount_xxxx_post_office",
  //         "isInvestment": true,
  //         "accountNumber": "xxxx",
  //         "type": "savingAccount",
  //         "transactions": [
  //           {
  //             "date": "2024-09-23",
  //             "amount": 21000,
  //             "id": 1
  //           }
  //         ]
  //       },
  //       "mutualFund_Daily MF SIP_paytm": {
  //         "isInvestment": true,
  //         "bankName": "Paytm",
  //         "accountNumber": "Daily MF SIP",
  //         "type": "mutualFund",
  //         "id": "mutualFund_Daily MF SIP_paytm",
  //         "transactions": [
  //           {
  //             "date": "2024-09-23",
  //             "id": 1,
  //             "amount": 2800
  //           },
  //           {
  //             "date": "2024-09-24",
  //             "amount": 51,
  //             "id": 3
  //           },
  //           {
  //             "date": "2024-09-25",
  //             "amount": 51,
  //             "id": 3
  //           },
  //           {
  //             "date": "2024-09-26",
  //             "id": 4,
  //             "amount": 51
  //           },
  //           {
  //             "id": 5,
  //             "amount": 51,
  //             "date": "2024-09-27"
  //           }
  //         ],
  //         "customerName": "Aman Jain"
  //       },
  //       "ppf_55000007544061_hdfc_bank": {
  //         "id": "ppf_55000007544061_hdfc_bank",
  //         "transactions": [
  //           {
  //             "amount": 150000,
  //             "id": 1,
  //             "date": "2024-09-23"
  //           }
  //         ],
  //         "bankName": "HDFC Bank",
  //         "type": "ppf",
  //         "accountNumber": "55000007544061",
  //         "customerName": "Preeti Jain",
  //         "isInvestment": true
  //       },
  //       "savingAccount_9512004308_kotak_bank": {
  //         "id": "savingAccount_9512004308_kotak_bank",
  //         "isInvestment": true,
  //         "bankName": "Kotak Bank",
  //         "accountNumber": "9512004308",
  //         "transactions": [
  //           {
  //             "date": "2024-09-23",
  //             "amount": 1000,
  //             "id": 1
  //           }
  //         ],
  //         "customerName": "Aman Jain",
  //         "type": "savingAccount"
  //       },
  //       "mutualFund_Kotak Nifty Next 50 Index Fund_paytm_money": {
  //         "isInvestment": true,
  //         "id": "mutualFund_Kotak Nifty Next 50 Index Fund_paytm_money",
  //         "bankName": "Paytm Money",
  //         "transactions": [
  //           {
  //             "id": 1,
  //             "amount": 70000,
  //             "date": "2024-09-23"
  //           }
  //         ],
  //         "accountNumber": "Kotak Nifty Next 50 Index Fund",
  //         "customerName": "Preeti Jain",
  //         "type": "mutualFund"
  //       },
  //       "mutualFund_Mahindra Manulife Small Cap MF_paytm_money": {
  //         "bankName": "Paytm Money",
  //         "id": "mutualFund_Mahindra Manulife Small Cap MF_paytm_money",
  //         "transactions": [
  //           {
  //             "id": 1,
  //             "date": "2024-09-23",
  //             "amount": 109000
  //           }
  //         ],
  //         "type": "mutualFund",
  //         "accountNumber": "Mahindra Manulife Small Cap MF",
  //         "customerName": "Aman Jain",
  //         "isInvestment": true
  //       },
  //       "mutualFund_901102487621_axis_bank": {
  //         "customerName": "Aman Jain",
  //         "bankName": "Axis Bank",
  //         "isInvestment": true,
  //         "id": "mutualFund_901102487621_axis_bank",
  //         "transactions": [
  //           {
  //             "id": 1,
  //             "amount": 77666,
  //             "date": "2024-09-23"
  //           }
  //         ],
  //         "accountNumber": "901102487621",
  //         "type": "mutualFund"
  //       },
  //       "cash_NA_preeti": {
  //         "transactions": [
  //           {
  //             "amount": 45000,
  //             "date": "2024-09-23",
  //             "id": 1
  //           }
  //         ],
  //         "id": "cash_NA_preeti",
  //         "isInvestment": true,
  //         "accountNumber": "NA",
  //         "type": "cash",
  //         "bankName": "Preeti",
  //         "customerName": "Preeti Jain"
  //       },
  //       "homeLoan_3001010000410_lic_housing": {
  //         "bankName": "LIC Housing",
  //         "isInvestment": false,
  //         "id": "homeLoan_3001010000410_lic_housing",
  //         "accountNumber": "3001010000410",
  //         "transactions": [
  //           {
  //             "id": 1,
  //             "amount": 3000000,
  //             "date": "2023-03-05"
  //           },
  //           {
  //             "id": 2,
  //             "date": "2024-09-24",
  //             "amount": -98735
  //           }
  //         ],
  //         "subType": "LOAN",
  //         "customerName": "Aman jain",
  //         "type": "homeLoan"
  //       },
  //       "ppf_0205PPF0000000000005_uco_bank": {
  //         "id": "ppf_0205PPF0000000000005_uco_bank",
  //         "isInvestment": true,
  //         "customerName": "Aman Jain",
  //         "type": "ppf",
  //         "transactions": [
  //           {
  //             "date": "2024-09-23",
  //             "id": 1,
  //             "amount": 248280
  //           }
  //         ],
  //         "accountNumber": "0205PPF0000000000005",
  //         "bankName": "UCO Bank"
  //       }
  //     },
  //     "earnings": {}
  //   };

  //   Object.keys(data.investments).forEach(function (key) {
  //     if (!data.investments[key].subType) {
  //       data.investments[key].subType = 'INVESTMENT';
  //     }
  //   });
  //   (async () => {
  //     await FirbaseDbService.addDataInFirestoreDb(
  //       'users',
  //       'p1llcR38f3QrNUhyQnWvFZavmUk1',
  //       data
  //     )
  //   })();
  // }, []);


  return (
    <>
      <IonApp>
        <PushNotificationsWrapper>
          <IonReactRouter >
            <RootWrapper />
          </IonReactRouter>
        </PushNotificationsWrapper>
      </IonApp >
    </>
  )
};

export default App;
