export const LoaderService = {
    show: () => {
        let loaderDiv: any = document.getElementById('loader');
        if (loaderDiv) {
            loaderDiv.style.display = 'block';
        }

        let overlay: any = document.getElementById('loader-overlay');
        if (overlay) {
            overlay.style.display = 'block';
        }
    },

    hide: () => {
        let loaderDiv: any = document.getElementById('loader');
        if (loaderDiv) {
            loaderDiv.style.display = 'none';
        }

        let overlay: any = document.getElementById('loader-overlay');
        if (overlay) {
            overlay.style.display = 'none';
        }
    }
}