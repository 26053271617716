import SearchPage from './SearchPage';

const Search: React.FC<any> = () => {

    return (
        <div className="ion-padding">
            <SearchPage />
        </div>
    )
}
export default Search;