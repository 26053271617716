import DashboardPage from './DashboardPage';

const Dashboard: React.FC<any> = () => {

    return (
        <div className="ion-padding" style={{ paddingTop: 0 }}>
            <DashboardPage
                isViewSummary={false}
            />
        </div>
    )
}
export default Dashboard;