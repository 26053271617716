import { EventEmitter } from 'events';

class ToastService {
    eventEmitter: EventEmitter;

    constructor() {
        this.eventEmitter = new EventEmitter();
    }

    showSuccessToast(props: any) {
        this.eventEmitter.emit('showToast', { ...props, color: 'success' });
    }

    showErrorToast(props: any) {
        this.eventEmitter.emit('showToast', { ...props, color: 'danger' });
    }
}

export const toastService = new ToastService();