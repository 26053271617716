import { App } from '@capacitor/app';
import { ROUTES } from './constants/routes.constant';

export const BackBtnHandler = {
    init: () => {
        document.addEventListener('ionBackButton', (ev: any) => {
            ev.detail.register(-1, () => {
                if (location.pathname == ROUTES.DASHBOARD.path ||
                    location.pathname == ROUTES.LOGIN.path ||
                    location.pathname == ROUTES.VERIFY_OTP.path) {
                    App.exitApp();
                }
            });
        });
    }
}