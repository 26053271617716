import SignUpPage from './SignUpPage';

const SignUp: React.FC<any> = ({ pageConfig }) => {

    return (
        <div className='content-middle'>
            <div className="ion-padding">
                <SignUpPage pageConfig={pageConfig} />
            </div>
        </div>
    )
}
export default SignUp;