import React, { useCallback, useEffect, useRef, useState } from 'react';
import AllSections from '../AllSections/AllSections';
import { IonButton, IonIcon } from '@ionic/react';
import { addCircle, removeCircle } from 'ionicons/icons';
import { modalService } from '../../services/modal.service';
import useRoute from '../../hooks/useRoute';

const Form: React.FC<any> = ({ data, onSubmit, onDataChange, ...otherProps }) => {
    const [formData, setFormData] = useState<any>(null);
    const [refresh, setRefresh] = useState(false);
    const { queryParams } = useRoute();

    // useEffect(() => {
    //     if (otherProps?.formFields) {
    //         const obj: any = {};
    //         otherProps.formFields.forEach((field: any) => {
    //             if (field.value) {
    //                 obj[field.name] = field.value;
    //             }
    //         });
    //         setFormData(obj);
    //     }
    // }, []);

    useEffect(() => {
        if (data) {
            setFormData(data);
        } else if (queryParams) {
            setFormData(queryParams);
        }
    }, [data]);

    const handleChange = (e: any, index?: number, type?: string) => {
        let obj;
        if (type === 'checkbox') {
            const updatedArray = [...(formData?.[e.target.name] || [])];
            if (e.target.checked) {
                updatedArray.push(e.target.value);
            } else {
                updatedArray.splice(updatedArray.indexOf(e.target.value), 1);
            }
            obj = {
                ...formData,
                [e.target.name]: updatedArray,
            };
        } else
            if (index !== undefined) {
                const updatedArray = [...(formData?.[e.target.name] || [])];
                updatedArray[index] = e.target.value;
                obj = {
                    ...formData,
                    [e.target.name]: updatedArray,
                };
            } else {
                obj = {
                    ...formData,
                    [e.target.name]: e.target.value,
                };
            }
        setFormData(obj);
        onDataChange && onDataChange(obj);
    };

    const handleSubmit = useCallback((e: any) => {
        e.stopPropagation();
        e.preventDefault(); // Prevent form submission
        onSubmit && onSubmit(formData);
    }, [onSubmit, formData]);

    const handleItemClick = useCallback((field: any) => {
        onSubmit && onSubmit(field);
    }, [onSubmit]);

    const medicinePrescribedView = (item: any) => {
        return <>
            {`${item.medicineName} ${item.dosage ? item.dosage + ' ' + item.dosageUnit : ''} `}
            {item.duration && `for ${item.duration} ${item.durationUnit}`}
            {item.frequency && <>
                <br />
                {item.frequency.map((freq: string) => freq.charAt(0).toUpperCase() + freq.slice(1)).join(", ")}
            </>}
            {item.whenToTake && <>
                <br />
                {item.whenToTake.replace(/([A-Z])/g, ' $1').trim().charAt(0).toUpperCase() + item.whenToTake.replace(/([A-Z])/g, ' $1').trim().slice(1)}
            </>}
            {item.instruction && <>
                <br />
                {item.instruction}
            </>}
        </>
    }

    const renderInput = (field: any) => {
        return (
            <>
                {field.label && <label className="form-label" htmlFor="basic-icon-default-fullname">
                    {field.label}
                    {field.mandatory && <span style={{ color: 'red' }}> *</span>}
                </label>}
                <div className={`${field.iconClass ? 'input-group input-group-merge' : ''} ${field.inputWrapperClass}`}>
                    {field.iconClass && <span className="input-group-text"><i className={`bx ${field.iconClass}`}></i></span>}
                    {renderInputField(field)}
                </div>
            </>
        )
    }

    const renderInputField = (field: any) => {
        if (field.type == 'date' && field.fillCurrentDate && formData && !formData?.[field.name]) {
            formData[field.name] = new Date().toISOString().split('T')[0];
        }
        switch (field.type) {
            case 'view':
                if (field.tableKey == 'medicines') {
                    return <div className={`${field.className}`}>
                        {formData?.[field.tableKey]?.length ? <ul style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>
                            {formData?.[field.tableKey]?.map((item: any, index: number) => (
                                <React.Fragment>
                                    <li key={index}>
                                        <>
                                            {medicinePrescribedView(item)}
                                        </>
                                    </li>
                                </React.Fragment>
                            ))}
                        </ul> : '-'}
                    </div>
                }
                return (
                    <div className={`${field.className}`}>
                        {field.value || formData?.[field.name] || '-'}
                    </div>
                );
            case 'anchor':
                return (
                    <a
                        className={`form-input ${field.className}`}
                        onClick={() => handleItemClick(field)}
                        {...field}>
                        {field.text}
                    </a>
                );
            case 'textarea':
                return (
                    <textarea
                        required={field.mandatory}
                        style={field.style}
                        placeholder={field.placeholder}
                        className={`form-input ${field.className}`}
                        name={field.name}
                        value={formData?.[field.name]}
                        onChange={handleChange}
                        autoFocus={field.autoFocus}
                        maxLength={field.maxlength}
                        {...field}
                    />
                );
            case 'radio':
                return (
                    <>
                        {field.options?.map((option: any, index: number) => {
                            if (!formData?.[field.name]) handleChange({ target: { name: field.name, value: field.options[0].value } })
                            const formIndex = Math.random();
                            return <div className="form-check form-check-inline">
                                <React.Fragment key={index}>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name={field.name}
                                        id={`${field.name}_${formIndex}`}
                                        value={option.value}
                                        checked={formData?.[field.name] === option.value}
                                        onChange={handleChange}
                                    />
                                    <label className="form-check-label" htmlFor={`${field.name}_${formIndex}`}>{option.label}</label>
                                </React.Fragment>
                            </div>
                        })}
                    </>
                );

            case 'checkbox':
                return (
                    <>
                        {field.options?.map((option: any, index: number) => {
                            const formIndex = Math.random();
                            return <div className={`form-check form-check-inline ${field.optionClassName}`}>
                                <React.Fragment key={index}>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name={field.name}
                                        id={`${field.name}_${formIndex}`}
                                        value={option.value}
                                        checked={formData?.[field.name]?.includes(option.value)}
                                        onChange={(e) => { handleChange(e, index, 'checkbox'); }}
                                    />
                                    <label className="form-check-label" htmlFor={`${field.name}_${formIndex}`}>{option.label}</label>
                                </React.Fragment>
                            </div>
                        })}
                    </>
                )
            case 'select':
                if (!formData?.[field.name]) handleChange({ target: { name: field.name, value: field.options[0].value } })
                return (
                    <div {...field.fieldRootAttr}>
                        <select
                            required={field.mandatory}
                            style={field.style}
                            placeholder={field.placeholder}
                            className={`form-input ${field.className}`}
                            name={field.name}
                            value={formData?.[field.name]}
                            onChange={handleChange}
                            autoFocus={field.autoFocus}
                            {...field}
                        >
                            {field.options?.map((option: any, index: number) => (
                                <option key={index} value={option.value}>{option.label}</option>
                            ))}
                        </select>
                        {formData?.[field.name] && field.options?.find((option: any) => option.value == formData?.[field.name])?.formFields?.map((field: any, index: number) => (
                            <div key={index} className={field.wrapperClassName} style={field.wrapperStyle}>
                                {renderInput(field)}
                            </div>
                        ))}
                    </div>
                );


            case 'submit':
            case 'button':
                return (
                    <>
                        {formData?.[field.tableKey] && formData?.[field.tableKey]?.length ? <ul style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>
                            {formData?.[field.tableKey]?.map((item: any, index: number) => (
                                <React.Fragment>
                                    <li key={index}>
                                        <>
                                            {field.tableKey == 'medicines' && medicinePrescribedView(item)}
                                            {field.tableKey != 'medicines' && item}
                                        </>
                                    </li>
                                    <a style={{ color: '#3880ff' }}
                                        onClick={() => {
                                            if (field.redirectionModal) {
                                                modalService.showModal({
                                                    ...{ [field.tableKey]: formData?.[field.tableKey] },
                                                    ...field.redirectionModal,
                                                    onSubmit: (data: any) => {
                                                        setFormData({ ...(formData || {}), ...data });
                                                    }
                                                });
                                            }
                                        }}>
                                        Edit
                                    </a>
                                    <a style={{ color: '#3880ff', marginLeft: 10 }}
                                        onClick={() => {
                                            formData?.[field.tableKey].splice(index, 1);
                                            setFormData({ ...formData });
                                        }}>
                                        Remove
                                    </a>
                                </React.Fragment>
                            ))}
                        </ul> : ''}
                        <button {...field} onClick={() => {
                            if (field.redirectionModal) {
                                modalService.showModal({
                                    ...{ [field.tableKey]: formData?.[field.tableKey] },
                                    ...field.redirectionModal,
                                    onSubmit: (data: any) => {
                                        setFormData({ ...(formData || {}), ...data });
                                    }
                                });
                            }
                            otherProps.onClick && otherProps.onClick({ ...field, formData });
                        }}>
                            {field.text}
                        </button>
                    </>
                );
            case 'ion-button':
                return (
                    <IonButton type="button" {...field} onClick={handleSubmit}>
                        {field.icon && <IonIcon icon={field.icon} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>}
                        {field.text}
                    </IonButton>
                );
            case 'html':
                return (
                    <div
                        {...field}
                        dangerouslySetInnerHTML={{ __html: field.html }}>
                    </div>
                );
            case 'list-item':
                if (formData?.[field.name]) field.value = formData?.[field.name];
                if (!field.value || !field.value.length) field.value = [""];
                let inputRefs = useRef<Array<HTMLInputElement | null>>([]);
                return (
                    <div>
                        {field.value?.map((value: string, index: number) => (
                            <div className='mb-3' key={index} style={{ display: 'flex' }}>
                                <input
                                    onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                        if (e.key === 'Enter' && index == field.value.length - 1) {
                                            if (!field.value) field.value = [];
                                            field.value.push('');
                                            setRefresh(!refresh);
                                            // Move focus to the next input field
                                            inputRefs.current[index + 1]?.focus();
                                            // document.getElementById(`${field.name}_${index + 1}`).focus();
                                            e.preventDefault();
                                        }
                                    }}
                                    id={`${field.name}_${index}`}
                                    ref={(ref) => (inputRefs.current[index] = ref)}
                                    required={field.mandatory}
                                    style={field.style}
                                    type={field.type as any}
                                    autoFocus={field.autoFocus}
                                    placeholder={field.placeholders?.[index] || field.placeholder}
                                    className={`form-input ${field.className}`}
                                    name={`${field.name}`}
                                    pattern={field.pattern}
                                    maxLength={field.maxlength}
                                    value={formData?.[field.name]?.[index]}
                                    onChange={(e) => { handleChange(e, index); }}
                                />
                                {index === field.value.length - 1 && <IonIcon
                                    onClick={() => {
                                        if (!field.value) field.value = [];
                                        field.value.push('');
                                        setRefresh(!refresh);
                                        inputRefs.current[index + 1]?.focus();
                                    }}
                                    size='large'
                                    style={{ marginLeft: 10 }}
                                    icon={addCircle} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                </IonIcon>}
                                {field.value.length !== 1 && <IonIcon
                                    onClick={() => {
                                        field.value.splice(index, 1);
                                        formData?.[field.name]?.splice(index, 1);
                                        setFormData({ ...formData });
                                    }}
                                    size='large'
                                    style={{ marginLeft: 10 }}
                                    icon={removeCircle} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                </IonIcon>}
                            </div>
                        ))}
                    </div>
                );

            case 'merge':
                return (
                    <div className={field.wrapperClassName} style={{ ...field.wrapperStyle, ...{ display: 'flex', justifyContent: 'space-between' } }}>
                        {field.inputs.map((input: any) => {
                            return renderInput(input);
                        })}
                    </div>
                );
            default:
                return (
                    <input
                        required={field.mandatory}
                        style={field.style}
                        type={field.type as any}
                        autoFocus={field.autoFocus}
                        placeholder={field.placeholder}
                        className={`form-input ${field.className}`}
                        name={field.name}
                        pattern={field.pattern}
                        maxLength={field.maxlength}
                        value={formData?.[field.name]}
                        onChange={handleChange}
                        {...field}
                    />
                );
        }
    }

    return (
        <div>
            <div className={`${otherProps.isCard && 'card'} mb-4`}>
                <div className={`${otherProps.isCard && 'card-body'}`} style={{ background: otherProps.bgColor }}>
                    <AllSections sections={otherProps.sections || []} />
                    <form onSubmit={handleSubmit}>
                        {otherProps.formFields?.map((field: any, index: number) => (
                            <div key={index} className={field.wrapperClassName} style={field.wrapperStyle}>
                                {renderInput(field)}
                                {field.helpHtml && <div dangerouslySetInnerHTML={{ __html: field.helpHtml }}></div>}
                            </div>
                        ))}
                    </form>
                </div >
            </div >
        </div >
    );
};

export default Form;