import VerifyOtpPage from './VerifyOtpPage';

const VerifyOtp: React.FC<any> = ({ pageConfig }) => {

    return (
        <div className='content-middle'>
            <div className="ion-padding">
                <VerifyOtpPage pageConfig={pageConfig} />
            </div>
        </div>
    )
}
export default VerifyOtp;