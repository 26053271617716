import { CALENDAR_CONSTANTS } from "../constants/calendar.constants";

export const DateUtil = {
    isToday: (date: Date) => {
        let currentDate = `${new Date().getMonth() + 1} ${new Date().getDate()} ${new Date().getFullYear()}`;
        let allDate = `${date.getMonth() + 1} ${date.getDate()} ${date.getFullYear()}`;
        return currentDate == allDate;
    },

    isEqual: (date1: Date, date2: Date) => {
        return date1.getTime() == date2.getTime();
    },

    isLessThanToday: (date: Date) => {
        let currentDate = `${new Date().getMonth() + 1} ${new Date().getDate()} ${new Date().getFullYear()}`;
        let allDate = `${date.getMonth() + 1} ${date.getDate()} ${date.getFullYear()}`;
        return new Date(currentDate).getTime() > new Date(allDate).getTime();
    },

    isGreaterThanToday: (date: Date) => {
        let currentDate = `${new Date().getMonth() + 1} ${new Date().getDate()} ${new Date().getFullYear()}`;
        let allDate = `${date.getMonth() + 1} ${date.getDate()} ${date.getFullYear()}`;
        return new Date(currentDate).getTime() < new Date(allDate).getTime();
    },

    isGreaterThanOrEqualToday: (date: Date) => {
        let currentDate = `${new Date().getMonth() + 1} ${new Date().getDate()} ${new Date().getFullYear()}`;
        let allDate = `${date.getMonth() + 1} ${date.getDate()} ${date.getFullYear()}`;
        return new Date(currentDate).getTime() <= new Date(allDate).getTime();
    },

    belongsToCurrentMonth: (date: Date) => {
        return (date.getMonth() == new Date().getMonth()) &&
            date.getFullYear() == new Date().getFullYear();
    },

    getMidnightDate: (date: Date = new Date()) => {
        return new Date(new Date(date).setHours(0, 0, 0, 0));
    },

    getTodayDate: () => {
        return DateUtil.getDMMDDYYYY(new Date());
    },

    getDDMMYYYY: (date: Date) => {
        return `${new Date(date).getDate() < 10 ? '0' + new Date(date).getDate() : new Date(date).getDate()}${(new Date(date).getMonth() + 1) < 10 ? '0' + (new Date(date).getMonth() + 1) : new Date(date).getMonth() + 1}${new Date(date).getFullYear()}`;
    },

    getModifiedDDMMYYYY: (date: Date) => {
        return `${DateUtil.getMMwithSuffix(date)} ${CALENDAR_CONSTANTS.MONTHS[new Date(date).getMonth() + 1]}, ${new Date(date).getFullYear()}`;
    },

    getDateFromModifiedDate: (d: string): any => {
        let day = +d.substring(0, 2);
        let month = +d.substring(2, 4);
        let year = +d.substring(4, 8);
        let date = new Date(month + '-' + day + '-' + year);
        return date;
    },

    getDMMDDYYYYFromModifiedDate: (date: Date) => {
        return `${CALENDAR_CONSTANTS.DAYS[new Date(date).getDay()]}, ${DateUtil.getMMwithSuffix(date)} ${CALENDAR_CONSTANTS.MONTHS[new Date(date).getMonth() + 1]}, ${new Date(date).getFullYear()}`;
    },

    getDMMDDYYYY: (date: Date) => {
        return `${CALENDAR_CONSTANTS.DAYS[new Date(date).getDay()]}, ${DateUtil.getMMwithSuffix(date)} ${CALENDAR_CONSTANTS.MONTHS[new Date(date).getMonth() + 1]}, ${new Date(date).getFullYear()}`;
    },

    getMMDDYYYY: (date: Date) => {
        return `${DateUtil.getMMwithSuffix(date)} ${CALENDAR_CONSTANTS.MONTHS[new Date(date).getMonth() + 1]}, ${new Date(date).getFullYear()}`;
    },

    getDDMMMHHMMZ: (date: Date) => {
        return `${DateUtil.getMMwithSuffix(date)} ${CALENDAR_CONSTANTS.MONTHS[new Date(date).getMonth() + 1]} ${DateUtil.getHHMMZ(new Date(date))}`;
    },

    getDDMMMYYYYHHMMZ: (date: Date) => {
        return `${DateUtil.getModifiedDDMMYYYY(date)} ${DateUtil.getHHMMZ(new Date(date))}`;
    },

    getMMwithSuffix: (date: Date) => {
        let d = new Date(date).getDate() + '';
        if (CALENDAR_CONSTANTS.DATE_SUFFIX[d]) {
            return `${d}${CALENDAR_CONSTANTS.DATE_SUFFIX[d]}`;
        }
        let lastNumberInDate = d[d.length - 1];
        return `${d}${CALENDAR_CONSTANTS.DATE_SUFFIX[lastNumberInDate] || CALENDAR_CONSTANTS.DATE_SUFFIX.default}`;
    },

    getHHMMZ: (date: Date) => {
        let hourIn24Format: any = new Date(date).getHours();
        let minutes: any = new Date(date).getMinutes();

        if (hourIn24Format < 10) {
            hourIn24Format = '0' + hourIn24Format;
        }
        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        if (hourIn24Format == 0) {
            hourIn24Format = '12';
        }
        if (hourIn24Format > 12) {
            let time: any = hourIn24Format - 12;
            if (time < 10) {
                time = '0' + time;
            }
            return `${time}:${minutes} PM`;
        } else if (hourIn24Format <= 12) {
            return `${hourIn24Format}:${minutes} AM`;
        };
    },

    doesNowExistsBetween: (startDate: Date, endDate: Date) => {
        const startDateTime = new Date(startDate);
        const endDateTime = new Date(endDate);
        const currentDateTime = new Date();
        if (currentDateTime >= startDateTime && currentDateTime <= endDateTime) {
            return true;
        }
        return false;
    },

    generateYearOptions: () => {
        return [
            {
                label: '2024',
                value: '2024'
            },
            ...Array.from({ length: 51 }, (_, index) => {
                const year = 1980 + index;
                return {
                    label: year,
                    value: year
                };
            })
        ];
    }


}