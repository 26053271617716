import { LOCAL_STORAGE_CONSTANT } from "../constants/localStorage.constant";

export const LocalStorageUtils = {

    setValueInLocalStorage: (key: string, value: string) => {
        localStorage.setItem(key, value);
    },

    getValueFromLocalStorage: (key: string) => {
        return <any>localStorage.getItem(key) || null;
    },

    getAllValuesFromLocalStorage: () => {
        let allItems: any = {};
        for (let i = 0; i < localStorage.length; i++) {
            let key = localStorage.key(i);
            if (key) {
                allItems[key] = localStorage.getItem(key);
            }
        }
        return JSON.stringify(allItems);
    },

    deleteKeyFromLocalStorage: (key: string) => {
        return <any>localStorage.removeItem(key);
    },

    logout: () => {
        LocalStorageUtils.deleteKeyFromLocalStorage(LOCAL_STORAGE_CONSTANT.SSO);
        LocalStorageUtils.deleteKeyFromLocalStorage(LOCAL_STORAGE_CONSTANT.UID);
    }

}