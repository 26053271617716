import { useEffect } from 'react';
import AllSections from '../../components/AllSections/AllSections';
import { FirebaseAuthService } from '../../services/FirebaseAuth.service';
import useSignIn from '../../hooks/useSignIn';
import useRedirect from '../../hooks/useRedirection';
import { ROUTES } from '../../constants/routes.constant';
import { toastService } from '../../services/toast.service';
import { FirebaseService } from '../../services/firebase.service';
import { setUserDetails } from '../../managers/user.manager';
let buttonId = 'sign-in-button';

interface LoginPageProps {
    pageConfig: any;
}

const LoginPage: React.FC<LoginPageProps> = ({ pageConfig }) => {

    const { sendOtp, signUpWithEmail, loginWithEmail, loginWithGoogle, loginWithUID } = useSignIn();
    const { redirectTo } = useRedirect();

    const initializeRecaptchaVerifierWithRetry = (id: string, maxRetries: number) => {
        const element = document.getElementById(id);
        if (element) {
            FirebaseAuthService.initializeRecaptchaVerifier(id);
        } else if (maxRetries > 0) {
            setTimeout(() => {
                initializeRecaptchaVerifierWithRetry(id, maxRetries - 1);
            }, 1000); // Retry after 1 second
        }
    };

    useEffect(() => {
        initializeRecaptchaVerifierWithRetry(buttonId, 5); // Retry 5 times
    }, []);


    // useEffect(() => {
    //     signUpWithEmail({
    //         email: 'amanjain325@gmail.com',
    //         password: 'jain@2811'
    //     }, async (resp: any) => {
    //         await FirebaseService.loginOrSignUpUser(resp.uid);
    //     }, (err: any) => {
    //         debugger;
    //     });
    // }, []);

    return (
        <AllSections {...pageConfig?.LOGIN} handleClick={(section: any, formData: any) => {
            const handleLogin = (loginMethod: Function) => {
                loginMethod((resp: any) => {
                    setUserDetails(resp);
                    redirectTo({ redirectionUrl: ROUTES.DASHBOARD.path }, true);
                    toastService.showSuccessToast({ message: 'Login successful.' });
                }, (error: any) => {
                    toastService.showErrorToast({ message: error.message });
                });
            };

            if (document.getElementById('login-with-email-pwd')) {
                handleLogin((callback: Function, errorCallback: Function) => loginWithEmail(formData, callback, errorCallback));
            } else if (document.getElementById('login-with-google')) {
                handleLogin(loginWithGoogle);
            } else if (document.getElementById('login-with-uid')) {
                handleLogin((callback: Function, errorCallback: Function) => loginWithUID(formData, callback, errorCallback));
            } else if (document.getElementById('login-with-otp')) {
                handleLogin((callback: Function, errorCallback: Function) => sendOtp(formData, callback, errorCallback));
            }
        }
        } />
    );
};

export default LoginPage;
