import { SpendManagerService } from "../services/spend-manager.service";

export default function useSpendManager() {

    const addSpend = async (payload: any, onSuccess: Function, onError: Function) => {
        try {
            let resp = await SpendManagerService.addSpend(payload);
            onSuccess(resp);
        } catch (error) {
            onError(error);
        }
    };

    const updateSpend = async (payload: any, onSuccess: Function, onError: Function) => {
        try {
            let resp = await SpendManagerService.updateSpend(payload);
            onSuccess(resp);
        } catch (error) {
            onError(error);
        }
    }

    const deleteSpend = async (spendId: string, onSuccess: Function, onError: Function) => {
        try {
            let resp = await SpendManagerService.deleteSpend(spendId);
            onSuccess(resp);
        } catch (error) {
            onError(error);
        }
    }

    return { addSpend, updateSpend, deleteSpend };
}