import { LOCAL_STORAGE_CONSTANT } from "../constants/localStorage.constant";
import { Route } from "../constants/routes.constant";
import { LocalStorageUtils } from "../utils/localStorage.util";

export const setUserDetails = (user: any) => {
    LocalStorageUtils.setValueInLocalStorage(LOCAL_STORAGE_CONSTANT.SSO, user.accessToken || user.user.accessToken);
    LocalStorageUtils.setValueInLocalStorage(LOCAL_STORAGE_CONSTANT.UID, user.uid || user.user.uid);
}

export const isUserLoggedIn = (route: Route) => {
    if (!route.authReq) return true;
    return LocalStorageUtils.getValueFromLocalStorage(LOCAL_STORAGE_CONSTANT.SSO);
}