import { child, get, ref, set } from "firebase/database";
import { FIREBASE_DB_CONSTANTS } from "../constants/firebase.constant";
import { firebaseDb, firebaseDbRef, firestoreDb } from "../Firebase";
import { DataService } from "./data.service";
import { LoaderService } from "./loader.service";
import { LocalStorageUtils } from "../utils/localStorage.util";
import { removeUndefinedValues } from "../utils/common.util";
import { collection, getDocs, QuerySnapshot, DocumentData, doc, DocumentReference, getDoc, setDoc } from "firebase/firestore";

export const FirbaseDbService = {

    checkIfDocExists: async (collectionName: string, docId: string) => {
        LoaderService.show();
        try {
            const docRef: DocumentReference<DocumentData> = doc(firestoreDb, collectionName, docId);
            const docSnap = await getDoc(docRef);
            return docSnap.exists();
        } catch (e) {
            console.error(e);
            throw e;
        } finally {
            LoaderService.hide();
        }
    },

    addDataInFirestoreDb: async (collectionName: string, docId: string, payload: any = {}) => {
        LoaderService.show();
        try {
            const sanitizedPayload = removeUndefinedValues(payload);
            await setDoc(doc(firestoreDb, collectionName, docId), sanitizedPayload);
        } catch (e) {
            throw e;
        } finally {
            LoaderService.hide();
        }
    },

    updateDataInFirestoreDb: async (collectionName: string, docId: string, payload: any = {}) => {
        LoaderService.show();
        try {
            const sanitizedPayload = removeUndefinedValues(payload);
            await setDoc(doc(firestoreDb, collectionName, docId), sanitizedPayload, { merge: true });
        } catch (e) {
            throw e;
        } finally {
            LoaderService.hide();
        }
    },

    getCollectionFromFirestoreDb: async (collectionName: string) => {
        LoaderService.show();
        try {
            const collectionRef = collection(firestoreDb, collectionName);
            const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(collectionRef);
            const documents = querySnapshot.docs.map(doc => doc.data());
            return documents;
        } catch (e) {
            console.error(e);
            throw e;
        } finally {
            LoaderService.hide();
        }
    },

    getDataFromFirestoreDb: async (collectionName: string, docId: string) => {
        LoaderService.show();
        try {
            const docRef: DocumentReference<DocumentData> = doc(firestoreDb, collectionName, docId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                return docSnap.data();
            } else {
                return null;
            }
        } catch (e) {
            console.error(e);
            throw e;
        } finally {
            LoaderService.hide();
        }
    },

    addDataInRealtimeDb: (dbPath: string, value: any) => {
        LoaderService.show();
        try {
            set(ref(firebaseDb, dbPath), value);
        } finally {
            LoaderService.hide();
        }
    },

    getToolbarIconsConfig: async () => {
        return await FirbaseDbService.getDataFromStore(FIREBASE_DB_CONSTANTS.TOOLBAR_ICONS_CONFIG.dbpath);
    },

    getHeaderConfig: async () => {
        return await FirbaseDbService.getDataFromStore(FIREBASE_DB_CONSTANTS.HEADER_CONFIG.dbpath);
    },

    getFooterConfig: async () => {
        return await FirbaseDbService.getDataFromStore(FIREBASE_DB_CONSTANTS.FOOTER_CONFIG.dbpath);
    },

    getPageConfig: async () => {
        return await FirbaseDbService.getDataFromStore(FIREBASE_DB_CONSTANTS.PAGE_CONFIG.dbpath);
    },

    getAllConstants: async () => {
        return await FirbaseDbService.getDataFromStore(FIREBASE_DB_CONSTANTS.CONSTANTS.dbpath);
    },

    getDataFromStore: async (dbPath: string, config: { showLoader?: boolean, defaultValue?: any } = { showLoader: true, defaultValue: null }) => {
        config = { showLoader: true, defaultValue: null, ...config };
        if (DataService.get(dbPath)) {
            return DataService.get(dbPath);
        }
        config?.showLoader && LoaderService.show();
        return get(child(firebaseDbRef, dbPath)).then((snapshot) => {
            LoaderService.hide();
            if (snapshot.exists()) {
                DataService.set(dbPath, snapshot.val());
                LocalStorageUtils.setValueInLocalStorage(dbPath, JSON.stringify(snapshot.val()));
                return snapshot.val();
            } else {
                return config?.defaultValue || null;
            }
        }).catch((error) => {
            LoaderService.hide();
            console.error(error);
        });
    }
}