import React from 'react';

export interface HtmlContentProps {
    html: string;
}

const HtmlContent: React.FC<HtmlContentProps> = ({ html }: HtmlContentProps) => (
    <div dangerouslySetInnerHTML={{ __html: html }} />
)
export default HtmlContent;
