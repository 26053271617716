import './TransactionDetailsPage.css';
import React, { useEffect, useState } from 'react';
import useRedirect from '../../hooks/useRedirection';
import { AssetsManagerService } from '../../services/assets-manager.service';
import { useParams } from 'react-router';
import CustomList from '../../components/CustomList/CustomList';
import useAssetManager from '../../hooks/useAssetManager';
import IconButton from '@mui/joy/IconButton';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { ROUTES } from '../../constants/routes.constant';
import { toastService } from '../../services/toast.service';
import { formatDate } from '../../utils/common.util';

const TransactionDetailsPage: React.FC<any> = () => {
    const { redirectTo } = useRedirect();
    let { assetType, assetId } = useParams<any>();
    const [assets, setAssets] = useState<any>([]);
    const [accountTypeDetails, setAccountTypeDetails] = useState<any>({});
    const { addInvestment, deleteTransaction } = useAssetManager();

    const fetchData = async () => {
        try {
            const [assetsResp, accountTypeDetails]: any = await Promise.allSettled([
                AssetsManagerService.getInvestment({ assetType, assetId }),
                AssetsManagerService.getAccountType(assetType)
            ]);
            setAssets(assetsResp.value.investments || []);
            setAccountTypeDetails(accountTypeDetails.value || {});
        } catch (error) {
            setAssets([]);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleClick = (cta: any, transaction: any) => {
        const { id } = transaction;
        if (cta.isDelete) {
            confirm('Are you sure you want to delete this asset?') &&
                deleteTransaction(assetId, id, (resp: any) => {
                    fetchData();
                    toastService.showSuccessToast({ message: resp.message });
                }, (err: any) => {
                    alert(err.message);
                });
        } else {
            redirectTo(cta, false, cta.queryParams);
        }
    }

    const syncAmount = () => {
        const amountInAccount = prompt('Enter the amount which is present in your account. A new transaction will be added with this amount.', '0');
        if (amountInAccount === null) {
            return; // User cancelled the prompt
        }
        const amount = +amountInAccount;
        if (!amount || isNaN(amount)) {
            return alert('Please enter a valid amount.');
        }

        const payload = {
            ...assets[0],
            isSynced: true,
            amount: amount - assets[0].amount,
            date: formatDate(new Date(), 'YYYY-MM-DD'),
        }

        addInvestment(payload, (resp: any) => {
            fetchData();
            toastService.showSuccessToast({ message: resp.message });
        }, (err: any) => {
            alert(err.message);
        });
    };

    return (
        <>
            {
                assets?.map((asset: any) => {
                    return <div key={asset.id}>
                        <IconButton
                            variant="plain"
                            color="neutral"
                            size="sm"
                            sx={{ position: 'absolute', zIndex: 999, right: '0.5rem', top: '0.5rem' }}
                        >
                            <EditOutlined onClick={() => {
                                redirectTo({ redirectionUrl: ROUTES.UPDATE_ASSET.path }, false, {
                                    ...asset,
                                    assetId: asset.id,
                                    items: undefined,
                                    cta1: undefined,
                                    cta2: undefined,
                                    transactionsList: undefined,
                                    isUpIcon: undefined,
                                    isDownIcon: undefined,
                                    accountType: undefined,
                                    transactions: undefined,
                                    type: undefined,

                                })
                            }} />
                        </IconButton>
                        <CustomList
                            items={
                                [
                                    { label: 'Type', value: asset.subType || 'NA', className: 'col-6' },
                                    { label: 'Account Category', value: accountTypeDetails.label, className: 'col-6' },
                                    { label: 'Bank Name', value: asset.bankName, className: 'col-6' },
                                    ...asset.items,
                                ]
                            }
                            type="STATIC_LIST" />
                        <hr className='mt-0' />
                        <div className='mb-2 text-center'>
                            <button className="btn btn-primary mr-10" onClick={() => redirectTo({ redirectionUrl: '/add-txn' }, false, {
                                type: assetType,
                                bankName: asset.bankName,
                                customerName: asset.customerName,
                                accountNumber: asset.accountNumber,
                            })}>
                                Add New Transaction
                            </button>
                            <button className="btn btn-secondary ml-3" onClick={() =>
                                syncAmount()
                            }>
                                Sync Amount
                            </button>
                        </div>
                        <div>
                            <label className="form-label">Transactions</label>
                            <CustomList
                                items={
                                    [
                                        ...asset.transactionsList
                                    ]
                                }
                                onItemCtaClick={(cta: any, transaction: any) => {
                                    handleClick(cta, transaction);
                                }}
                            />
                        </div>

                    </div>
                })
            }
        </>
    );
};

export default TransactionDetailsPage;