import React, { useEffect, useState } from 'react';
import AllSections from '../AllSections/AllSections';
import ReactModal from 'react-modal';
import styles from './Modal.module.css';
import { IonHeader, IonToolbar, IonRow, IonCol, IonIcon } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import CustomList from '../CustomList/CustomList';
import { modalService } from '../../services/modal.service';

const Modal: React.FC<any> = ({ pageConfig, modalProps, component }) => {
    const [props, setProps] = useState<any>({});
    const [isBrowserBack, setIsBrowserBack] = useState(false); // Added state for browser back


    useEffect(() => {
        if (modalProps.formName) {
            setProps({
                ...JSON.parse(JSON.stringify(pageConfig[modalProps.formName])),
                ...JSON.parse(JSON.stringify(modalProps)),
                onSubmit: modalProps.onSubmit,
                onSuccess: modalProps.onSuccess
            });
        } else {
            setProps({
                ...JSON.parse(JSON.stringify(modalProps)),
                onSubmit: modalProps.onSubmit,
                onSuccess: modalProps.onSuccess
            });
        }

        // Push a new state onto the history stack
        history.pushState({ modal: true }, 'Modal Opened', window.location.href);

    }, []);

    const handleCloseModal = () => {
        modalService.closeModal();
    };

    const handleCloseModalWithHistory = () => {
        history.back();
        handleCloseModal();
    };

    useEffect(() => {
        const handleBrowserBack = () => {
            setIsBrowserBack(true);
        };

        window.addEventListener('popstate', handleBrowserBack);

        return () => {
            window.removeEventListener('popstate', handleBrowserBack);
        };
    }, []);

    useEffect(() => {
        if (isBrowserBack) {
            handleCloseModal();
        }
    }, [isBrowserBack]);

    const renderModalContent = () => {
        if (component) {
            const Component = component;
            return <Component {...props} />
        } else if (props?.type == 'STATIC_LIST') {
            return <CustomList {...props} />
        } else {
            return <AllSections {...props} data={props} handleClick={(section: any, formData: any) => {
                switch (section.sectionType) {
                    case 'FORM':
                        props.onSubmit && props.onSubmit(formData);
                        handleCloseModalWithHistory();
                        break;
                    default:
                        // do nothing.
                        break;
                }
            }} />
        }
    }

    return (
        <ReactModal
            isOpen={true}
            className={styles.Modal}
            overlayClassName={styles.Overlay}>
            <IonHeader placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <IonToolbar placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IonRow placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
                        <IonCol size='1' onClick={handleCloseModalWithHistory} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
                            <IonIcon style={{ fontSize: 30 }} icon={closeOutline} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                        </IonCol>
                        <IonCol size='10' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <div style={{ marginLeft: 10, marginTop: 4 }}>{props?.title}</div>
                        </IonCol>
                    </IonRow>
                </IonToolbar>
            </IonHeader>
            <div className='ion-padding' style={{ paddingBottom: 150 }}>
                {renderModalContent()}
            </div>
        </ReactModal>
    );
};

export default Modal;