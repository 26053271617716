import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { Auth, getAuth, indexedDBLocalPersistence, initializeAuth } from "firebase/auth";
import { getDatabase, ref } from "firebase/database";
import { ENV_CONFIG } from "./config/config";
import { getFirestore } from "firebase/firestore";
import { Capacitor } from "@capacitor/core";

export const firebaseConfig = ENV_CONFIG.FIREBASE.CONFIG;

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// let auth1: any;

// if (Capacitor.isNativePlatform()) {
//     auth1 = initializeAuth(app, {
//         persistence: indexedDBLocalPersistence
//     });
// } else {
//     auth1 = getAuth(app);
// }


let firebaseAuth: Auth;

if (Capacitor.isNativePlatform()) {
    firebaseAuth = initializeAuth(app, {
        persistence: indexedDBLocalPersistence
    });
} else {
    firebaseAuth = getAuth(app);
}

export const auth = firebaseAuth;
// export const auth = getAuth(app);
export const firestoreDb = getFirestore(app);
export const firebaseDb = getDatabase();
export const firebaseDbRef = ref(getDatabase());
export const analytics = getAnalytics(app);
auth.settings.appVerificationDisabledForTesting = true;

// rules_version = '2';
// service cloud.firestore {
//   match /databases/{database}/documents {
//     match /{document=**} {
//       allow read, write: if false;
//     }
//   }
// }



