import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { filterItemsByMonth, formatCamelCaseString, formatCurrency } from '../../utils/common.util';
import { getPassbookData } from '../../mapper/passbook.mapper';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

export default function CustomTable({ selectedDate }) {
    const [rows, setRows] = React.useState([]);
    const [cashRows, setCashRows] = React.useState([]);
    const [totalEarningAmount, setTotalEarningAmount] = React.useState(0);
    const [totalInvestmentAmount, setTotalInvestmentAmount] = React.useState(0);
    const [totalSpendingAmount, setTotalSpendingAmount] = React.useState(0);
    const [earningRows, setEarningRows] = React.useState([]);
    const [investmentRows, setInvestmentRows] = React.useState([]);
    const [spendingRows, setSpendingRows] = React.useState([]);
    const [totalBalance, setTotalBalance] = React.useState(0);
    const [totalCashSpent, setTotalCashSpent] = React.useState(0);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            padding: 10,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            border: '1px solid #f0f0f0',
            padding: 10,
        }
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            // backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0
        },
    }));

    const createCashRows = (items) => {
        const _rows = [];
        let total = 0;
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            _rows.push({
                color: '#d32f2f',
                desc: item.customerName,
                desc1: item.desc1,
                amount: `${formatCurrency(item.amount)}`
            });
            total += +item.amount;
        }
        setTotalCashSpent(total);
        setCashRows(_rows);
    }

    const createRows = (items) => {
        const _rows = [];
        let balance = 0;
        let totalEarningAmount = 0;
        let totalInvestmentAmount = 0;
        let totalSpendingAmount = 0;
        let otherSpendingAmount = 0;
        const earnings = [];
        const investments = [];
        const spends = [];

        items.forEach((item) => {
            if (item.type == 'earning') {
                earnings.push(item);
            } else if (item.type == 'investment') {
                const existingInvestment = investments.find(investment => investment.accountNumber === item.accountNumber);
                if (existingInvestment) {
                    existingInvestment.amount = +existingInvestment.amount + +item.amount;
                } else {
                    investments.push(item);
                }
            } else if (item.type == 'monthlyExpense' || item.type == 'emi') {
                item.desc1 = `(${formatCamelCaseString(item.type)})`;
                spends.push(item);
            } else if (item.type == 'others') {
                otherSpendingAmount += +item.amount;
            } else {
                const isItemExist = spends.find((spend) => spend.type === item.type);
                if (isItemExist) {
                    const index = spends.findIndex((spend) => spend.type === item.type);
                    spends[index].customerName = formatCamelCaseString(item.type);
                    spends[index].amount = +spends[index].amount + +item.amount;
                } else {
                    item.customerName = formatCamelCaseString(item.type);
                    spends.push({ ...item });
                }
            }
        });

        if (earnings.length === 0) {
            earnings.push({
                amount: 0,
                subType: 'Earnings',
                note: ''
            });
        }
        // Sort each category by amount in descending order
        earnings.sort((a, b) => b.amount - a.amount);
        investments.sort((a, b) => b.amount - a.amount);
        spends.sort((a, b) => {
            if (a.type === 'monthlyExpense' && b.type !== 'monthlyExpense') {
                return -1;
            }
            if (a.type !== 'monthlyExpense' && b.type === 'monthlyExpense') {
                return 1;
            }
            if (a.type === 'monthlyExpense' && b.type === 'monthlyExpense') {
                return b.amount - a.amount;
            }
            return b.amount - a.amount;
        });

        // Process earnings
        earnings.forEach((item) => {
            _rows.push({
                color: '#1976d2',
                desc: formatCamelCaseString(item.subType),
                desc1: item.note && `(${item.note})`,
                amount: `${item.amount ? '+' : ''} ${formatCurrency(item.amount)}`
            });
            balance += +item.amount;
            totalEarningAmount += +item.amount;
        });

        // Process investments
        investments.forEach((item) => {
            _rows.push({
                color: 'green',
                desc: `${item.customerName} - ${item.accountNumber}`,
                desc1: `(${item.bankName})`,
                amount: `- ${formatCurrency(item.amount)}`
            });
            balance -= +item.amount;
            totalInvestmentAmount += +item.amount;
        });

        // Process monthly expenses
        spends.forEach((item) => {
            _rows.push({
                color: '#d32f2f',
                desc: item.customerName,
                desc1: item.desc1,
                amount: `- ${formatCurrency(item.amount)}`
            });
            balance -= +item.amount;
            totalSpendingAmount += +item.amount;
        });

        // Add other spending amount
        if (otherSpendingAmount > 0) {
            balance -= +otherSpendingAmount;
            _rows.push({
                color: '#d32f2f',
                desc: 'Others',
                amount: `- ${formatCurrency(otherSpendingAmount)}`
            });
        }
        setRows(_rows);
        setTotalBalance(balance);
        setTotalEarningAmount(totalEarningAmount);
        setTotalInvestmentAmount(totalInvestmentAmount);
        setTotalSpendingAmount(totalSpendingAmount + otherSpendingAmount);
        setEarningRows(earnings);
        setInvestmentRows(investments);
        setSpendingRows(spends.concat({ amount: otherSpendingAmount }));
    };

    React.useEffect(() => {
        const fetchData = async () => {
            const { allItems, spendsInCash } = await getPassbookData(true, selectedDate, false);
            const filteredData = filterItemsByMonth(allItems, selectedDate);
            const items = Object.values(filteredData).flat();
            createRows(items);
            createCashRows(spendsInCash);
        };

        fetchData();
    }, [selectedDate]);

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <StyledTableRow sx={{ backgroundColor: 'black' }}>
                            <StyledTableCell align="left" sx={{ color: 'white', width: '66%' }} colSpan={1}>Details</StyledTableCell>
                            <StyledTableCell align="right" sx={{ color: 'white' }}>Amount</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell sx={{ color: 'black' }}>
                                    {row.desc}
                                    <br></br>
                                    {row.desc1}
                                </StyledTableCell>
                                {/* {index === 0 && (
                                <StyledTableCell rowSpan={earningRows.length} align="center" sx={{ fontWeight: 700 }}>
                                    {formatCurrency(totalEarningAmount)}
                                </StyledTableCell>
                            )}
                            {index == earningRows.length && (
                                <StyledTableCell rowSpan={investmentRows.length} align="center" sx={{ fontWeight: 700 }}>
                                    {formatCurrency(totalInvestmentAmount)}
                                </StyledTableCell>
                            )}
                            {index == earningRows.length + investmentRows.length && (
                                <StyledTableCell rowSpan={spendingRows.length} align="center" sx={{ fontWeight: 700 }}>
                                    {formatCurrency(totalSpendingAmount)}
                                </StyledTableCell>
                            )} */}
                                <StyledTableCell sx={{ color: row.color }} align="right">{row.amount}</StyledTableCell>
                            </StyledTableRow>
                        ))}

                        <StyledTableRow sx={{ backgroundColor: 'lightgray' }}>
                            <StyledTableCell sx={{ fontWeight: 700 }} colSpan={1}>Balance</StyledTableCell>
                            <StyledTableCell sx={{ fontWeight: 700 }} align="right">{formatCurrency(totalBalance)}</StyledTableCell>
                        </StyledTableRow>

                    </TableBody>
                </Table>
            </TableContainer>


            {!!cashRows?.length && <div>
                <br />
                <Typography variant="h6" >CASH EXPENSES</Typography>
                <Divider className='mb-3' />

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <StyledTableRow sx={{ backgroundColor: 'black' }}>
                                <StyledTableCell align="left" sx={{ color: 'white', width: '66%' }} colSpan={1}>Details</StyledTableCell>
                                <StyledTableCell align="right" sx={{ color: 'white' }}>Amount</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {cashRows.map((row, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell sx={{ color: 'black' }}>
                                        {row.desc}
                                        <br></br>
                                        {row.desc1}
                                    </StyledTableCell>
                                    <StyledTableCell sx={{ color: row.color }} align="right">{row.amount}</StyledTableCell>
                                </StyledTableRow>
                            ))}

                            <StyledTableRow sx={{ backgroundColor: 'lightgray' }}>
                                <StyledTableCell sx={{ fontWeight: 700 }} colSpan={1}>Total</StyledTableCell>
                                <StyledTableCell sx={{ fontWeight: 700 }} align="right">{formatCurrency(totalCashSpent)}</StyledTableCell>
                            </StyledTableRow>

                        </TableBody>
                    </Table>
                </TableContainer>
            </div>}
        </>
    );
}
