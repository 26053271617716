import React, { Component, ErrorInfo } from 'react';
import Header from '../Header/Header';

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends Component<{}, ErrorBoundaryState> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        // You can log the error to a service like Sentry
        console.error('Error caught by ErrorBoundary:', error, errorInfo);
    }

    render(): React.ReactNode {
        if (this.state.hasError) {
            return (
                <>
                    <Header page={'ERROR_BOUNDARY'}></Header>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 120px)' }}>
                        <h6 className='mb-4'>Failed To Load</h6>
                        <button className='btn btn-primary' onClick={() => window.location.reload()}>
                            Retry
                        </button>
                    </div>
                </>
            );
        }

        return (this.props as { children: React.ReactNode }).children;
    }
}

export default ErrorBoundary;
