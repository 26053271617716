import React from 'react';
import Heading from '../../components/Heading/Heading';
import InfoCardsRow from '../../components/InfoCardsRow/InfoCardsRow';
import HtmlContent from '../../components/HtmlContent/HtmlContent';
import { DateUtil } from '../../utils/date.util';
import Form from '../Form/Form';
import CustomCarousel from '../CustomCarousel/CustomCarousel';
import CustomButton from '../Button/Button';
import Dropdown from '../Dropdown/Dropdown';
import { IonFab, IonFabButton, IonIcon, IonFabList } from '@ionic/react';
import useRedirect from '../../hooks/useRedirection';
import CustomList from '../CustomList/CustomList';
import BigCard from '../BigCard/BigCard';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const RenderSection = ({ data, section, redirectTo, handleClick, handleBtnClick }: any) => {
    switch (section && section.sectionType) {
        case 'HEADING':
            return <Heading {...section} />;
        case 'BUTTON':
            return <CustomButton {...section} onClick={(e: any) => {
                redirectTo({ ...section });
            }} />;
        case 'INFO_CARDS_ROW':
            return <InfoCardsRow section={section} handleClick={(item: any) => {
                redirectTo(item);
            }} />;
        case 'BIG_CARD':
            return <BigCard section={section} handleClick={(item: any) => {
                redirectTo(item);
            }} />;
        case 'HR':
            return <hr />;
        case 'DATE_PICKER':
            return <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker {...section} />
            </LocalizationProvider>;
        case 'LIST':
            return <CustomList {...section} />;
        case 'HTML_CONTENT':
            return <HtmlContent {...section} />;
        case 'CAROUSEL_SECTION':
            return <CustomCarousel {...section} onClick={(e: any) => {
                handleClick && handleClick(section, e);
            }} />;
        case 'DROPDOWN':
            return <Dropdown {...section} onListElementClicked={(e: any) => {
                handleClick && handleClick(section, e);
            }} />;
        case 'FORM':
            return <Form
                data={data}
                {...section?.FORM_CONFIG}
                onClick={(e: any) => {
                    handleBtnClick && handleBtnClick(section, e);
                }}
                onSubmit={(e: any) => {
                    handleClick && handleClick(section, e);
                }} />;
        default:
            return null;
    }
}

const AllSections: React.FC<any> = (props: any) => {
    const { rootConfig, rootConfig2, sections, fabIcons } = props;
    const { redirectTo } = useRedirect();

    return (
        <div {...rootConfig}>
            <div {...rootConfig2}>
                {sections?.map((section: any, index: number) => {
                    if (section.visiblityCriteria) {
                        if (section.visiblityCriteria.visible == false) return null;
                        if (section.visiblityCriteria.timings && !DateUtil.doesNowExistsBetween(section.visiblityCriteria.timings.startDateTime, section.visiblityCriteria.timings.endDateTime)) return null;
                    }
                    return (
                        <div {...section.styleConfig} key={index}>
                            {RenderSection({ ...props, section, redirectTo })}
                        </div>
                    );
                })}
            </div>
            <div>
                {!!fabIcons?.config && (
                    <IonFab {...fabIcons.config}>
                        {fabIcons?.buttons?.length > 1 ? (
                            <>
                                <IonFabButton color={fabIcons.config.color} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                    <IonIcon icon={fabIcons.config.icon} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                                </IonFabButton>
                                <IonFabList side={fabIcons.config.side} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                    {fabIcons?.buttons?.map((button: any, index: number) => (
                                        <IonFabButton key={index} onClick={() => redirectTo({ redirectionUrl: button.redirectionUrl })} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                            <IonIcon icon={button.icon} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                                        </IonFabButton>
                                    ))}
                                </IonFabList>
                            </>
                        ) : (
                            <IonFabButton
                                color={fabIcons.config.color}
                                onClick={() => redirectTo({ redirectionUrl: fabIcons.buttons[0].redirectionUrl })} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                {!!fabIcons?.buttons[0]?.icon && (
                                    <IonIcon icon={fabIcons.buttons[0].icon} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                                )}
                            </IonFabButton>
                        )}
                    </IonFab>
                )}
            </div>
        </div>
    );
};

export default AllSections;