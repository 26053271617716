import AddUpdateEarningsPage from './AddUpdateEarningsPage';

const AddUpdateEarnings: React.FC<any> = ({ pageConfig }) => {

    return (
        <div className="ion-padding">
            <AddUpdateEarningsPage {...pageConfig} />
        </div>
    )
}
export default AddUpdateEarnings;