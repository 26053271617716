import React, { useState } from 'react';
import { IonList, IonItem, IonLabel, IonIcon, IonSearchbar, IonAvatar, IonButton } from '@ionic/react';
import { chevronForward } from 'ionicons/icons';
import useRedirect from '../../hooks/useRedirection';
import { formatCamelCaseString, formatCurrency, formatDate, getInitials } from '../../utils/common.util';
import { LETTER_COLOR_MAP } from '../../utils/styles.util';
import { modalService } from '../../services/modal.service';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { CustomIcon } from './CustomIcon';
import IconButton from '@mui/joy/IconButton';
import DeleteOutlineOutlined from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlined from '@mui/icons-material/EditOutlined';

const CustomList: React.FC<any> = (props: any): any => {
    const { redirectTo } = useRedirect();
    const [searchText, setSearchText] = useState('');

    const handleSearch = (e: any) => {
        setSearchText(e.target.value);
    };

    const filteredItems = props?.items?.filter((item: any) =>
        props?.filterKeys?.some((key: string) =>
            typeof item[key] === 'string' && item[key].toLowerCase().includes(searchText.toLowerCase())
        ) || !props?.filterKeys
    );

    if (props.type == 'MUI_LIST') {
        return (
            <>
                {props?.filterKeys?.length && <IonSearchbar className='pt-4 pl-0 pr-0' value={searchText} onIonChange={handleSearch} placeholder={''} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonSearchbar>}

                <List>
                    {filteredItems.map((item: any, index: number) => {
                        return <>
                            <ListItem >
                                <ListItemAvatar>
                                    <Avatar
                                        variant="rounded"
                                        sx={{
                                            backgroundColor: item.type === 'investment' ? 'green' : (item.type === 'earning' ? 'primary.main' : 'error.main')
                                        }}>
                                        <CustomIcon type={item.type} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{
                                        color: item.type === 'investment' ? 'green' : (item.type === 'earning' ? 'primary.main' : 'error.main')
                                    }}
                                    primary={
                                        <div style={{ display: 'block', width: '80%' }}>

                                            <Typography
                                                component="span"
                                                variant="h6"
                                                sx={{ display: 'inline' }}
                                                color={item.type === 'investment' ? 'green' : (item.type === 'earning' ? 'primary.main' : 'error.main')}
                                            >
                                                {formatCurrency(item.amount)}
                                            </Typography>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                            >
                                                {item.customerName && ` — ${item.customerName}`}
                                            </Typography>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                            >
                                                {item.subType && ` — ${formatCamelCaseString(item.subType)}`}
                                            </Typography>
                                        </div>
                                    }
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="caption"
                                                sx={{ color: 'text.primary', display: 'inline' }}
                                            >
                                                {formatDate(item.date)}
                                            </Typography>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                            >
                                                {item.note && ` — ${item.note}`}
                                            </Typography>
                                            <br />
                                            {item.accountNumber && <Typography
                                                component="span"
                                                variant="caption"
                                                sx={{ color: 'text.primary', display: 'inline' }}
                                            >
                                                — {item.accountNumber}
                                            </Typography>}
                                            {item.type !== 'investment' && item.type !== 'earning' && <Typography
                                                component="span"
                                                variant="caption"
                                                sx={{ color: 'text.primary', display: 'inline' }}
                                            >
                                                — {formatCamelCaseString(item.type)}
                                            </Typography>}
                                        </React.Fragment>
                                    }
                                />
                                {props.hasEditCta && item.type !== 'investment' && <IconButton
                                    variant="plain"
                                    color="neutral"
                                    size="sm"
                                    sx={{ position: 'absolute', zIndex: 999, top: '0.875rem', right: '2.5rem' }}
                                >
                                    <EditOutlined onClick={() => {
                                        props.onEdit && props.onEdit(item);
                                    }} />
                                </IconButton>}
                                {props.hasDeleteCta && item.type !== 'investment' && <IconButton
                                    variant="plain"
                                    color="neutral"
                                    size="sm"
                                    sx={{ position: 'absolute', zIndex: 999, top: '0.875rem', right: '0.5rem' }}
                                >
                                    <DeleteOutlineOutlined onClick={() => {
                                        props.onDelete && props.onDelete(item);
                                    }} />
                                </IconButton>}
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </>
                    })}
                </List >
            </>
        );
    }

    if (props.type == 'STATIC_LIST') {
        return <div className="row">
            {filteredItems.map((item: any, index: number) => (
                <div className={item.className} key={index}>
                    <label className="form-label mt-0">{item.label}</label>
                    <p className='fs-8rem' dangerouslySetInnerHTML={{ __html: item.value }}></p>
                </div>
            ))}
        </div>
    }

    return (
        <>
            {props?.filterKeys?.length && <IonSearchbar className='pt-4 pl-0 pr-0' value={searchText} onIonChange={handleSearch} placeholder={''} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonSearchbar>}
            <IonList placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                {filteredItems?.map((item: any) => {
                    return (
                        <>
                            <IonItem key={item.id} style={item.style} onClick={() => {
                                if (item.redirectionModal) {
                                    modalService.showModal({
                                        ...item,
                                        ...item.redirectionModal
                                    });
                                } else {
                                    redirectTo(item);
                                }
                            }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                {item.icon &&
                                    <IonIcon style={{ paddingRight: '10px', color: 'black' }} size='medium' icon={item.icon} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                                }
                                {item.showIcon && (item.imgUrl ? (
                                    <IonAvatar style={{ marginRight: 16 }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
                                        <img src={item.imgUrl} alt="User image" />
                                    </IonAvatar>
                                ) : (
                                    <div className={item.tagClass} style={{ width: 30, height: 30, borderRadius: '50%', backgroundColor: LETTER_COLOR_MAP(item.fullName[0]), display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 16 }}>
                                        <h2 className={item.tagDataClass} style={{ color: '#fff', fontSize: 16, marginTop: 10 }}>{item.tag || getInitials(item.fullName)}</h2>
                                    </div>
                                ))}
                                <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                    <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{item.label}</IonLabel>
                                    {item.list && (
                                        <>
                                            {item.list.map((listItem: any, index: number) => (
                                                <p className='list-dot' key={index} dangerouslySetInnerHTML={{ __html: listItem }}></p>
                                            ))}
                                        </>
                                    )}
                                    {item.value && <p dangerouslySetInnerHTML={{ __html: item.value }}></p>}
                                    {item.description && <p dangerouslySetInnerHTML={{ __html: item.description }}></p>}
                                    {item.description1 && <p dangerouslySetInnerHTML={{ __html: item.description1 }}></p>}
                                    {item.description2 && <p dangerouslySetInnerHTML={{ __html: item.description2 }}></p>}
                                    {item.description3 && <p dangerouslySetInnerHTML={{ __html: item.description3 }}></p>}
                                    {item.description4 && <p dangerouslySetInnerHTML={{ __html: item.description4 }}></p>}
                                    {item.description5 && <p dangerouslySetInnerHTML={{ __html: item.description5 }}></p>}
                                    {item.description6 && <p dangerouslySetInnerHTML={{ __html: item.description6 }}></p>}
                                    {item.description7 && <p dangerouslySetInnerHTML={{ __html: item.description7 }}></p>}
                                </IonLabel>
                                {(item.redirectionUrl || item.redirectionModal) && <IonIcon slot="end" size='small' icon={chevronForward} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonIcon>}
                                {(item.cta1 || item.cta2) && <>
                                    <div>
                                        {item.cta1 && <button
                                            type="button"
                                            style={item.cta1.style}
                                            className={`${item.cta1.className}`}
                                            onClick={() => {
                                                props.onItemCtaClick && props.onItemCtaClick(item.cta1, item);
                                            }}
                                        >{item.cta1.ctaText}
                                        </button>}
                                        {item.cta2 && <button
                                            type="button"
                                            style={item.cta2.style}
                                            className={`${item.cta2.className}`}
                                            onClick={() => {
                                                props.onItemCtaClick && props.onItemCtaClick(item.cta2, item);
                                            }}
                                        >{item.cta2.ctaText}
                                        </button>}
                                    </div>
                                </>}
                            </IonItem>
                        </>
                    );
                })}
            </IonList>
        </>
    );
};

export default CustomList;