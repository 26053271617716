import { ERROR_MESSAGES } from "./constants/errors.constant";

export class CustomError {
    message: string;
    code: string = "DEFAULT";
    constructor(error: any = {}) {
        this.code = error.code || "DEFAULT";
        this.message = error.message || ERROR_MESSAGES[this.code] || ERROR_MESSAGES.DEFAULT;
    }
}
