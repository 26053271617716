import AllSections from "../../components/AllSections/AllSections";
import { formatCamelCaseString, formatCurrency } from "../../utils/common.util";

const PaymentFrequency: React.FC<any> = ({ data }) => {

    if (!data || !Object.keys(data).length) return null;

    const sections = [{
        sectionType: "INFO_CARDS_ROW",
        items: [
            {
                itemType: "CARD",
                description: "Payment Schedule",
                cardHeadingColor: "white",
                ...Object.keys(data).reduce((acc, key, index) => {
                    acc[`description${index + 1}`] = `${formatCamelCaseString(key)} - Paid: ${formatCurrency(data[key].paidTillNow)} | Remaining: ${formatCurrency(data[key].willBePaid)} | Total: ${formatCurrency(data[key].paidTillNow + data[key].willBePaid)}`;
                    return acc;
                }, {}),
                color: 'rgb(0 69 128)',
                style: {
                    marginTop: 2,
                    color: "white",
                    // backgroundColor: balance > 0 ? "rgb(0 69 128)" : balance < 0 ? "rgb(192 55 55)" : "rgb(192 172 103)"
                    backgroundColor: 'black'
                },
                cardHeight: 140,
                highlightedCard: true,
                highlightedCardAttr: {
                    // color: balance > 0 ? "primary" : balance < 0 ? "danger" : "warning",
                },
            },
        ],
    }];
    return (
        <div>
            <AllSections sections={sections} />
        </div>
    )
}
export default PaymentFrequency;