import React from 'react';
import './AddUpdateAssetPage.css';
import AllSections from '../../components/AllSections/AllSections';
import useAssetManager from '../../hooks/useAssetManager';
import useRedirect from '../../hooks/useRedirection';
import { useLocation } from 'react-router-dom';
import useRoute from '../../hooks/useRoute';

const AddUpdateAssetPage: React.FC<any> = (pageConfig) => {
    const { addInvestment, updateTransaction, updateInvestment, getInvestments } = useAssetManager();
    const { redirectTo } = useRedirect();
    const location = useLocation();
    const isEditTxnPage = location.pathname.includes('update-txn');
    const isAddTxnPage = location.pathname.includes('add-txn');
    const isUpdateAssetPage = location.pathname.includes('update-asset');
    const { queryParams: { transactionId, assetId } } = useRoute();
    const [newPageConfig, setNewPageConfig] = React.useState<any>(null);

    React.useEffect(() => {
        const getConifg = async () => {
            let config = null;
            if (isEditTxnPage) {
                config = pageConfig.UPDATE_TRANSACTION;
            } else if (isAddTxnPage) {
                config = pageConfig.ADD_TRANSACTION;
            } else if (isUpdateAssetPage) {
                config = pageConfig.UPDATE_ASSET;
            } else {
                config = pageConfig.ADD_ASSET;
            }
            setNewPageConfig(config);
        };

        getConifg();
    }, [isEditTxnPage, isAddTxnPage, isUpdateAssetPage, pageConfig.ADD_ASSET, pageConfig.ADD_TRANSACTION, pageConfig.UPDATE_ASSET, pageConfig.UPDATE_TRANSACTION]);

    const handleClick = (section: any, formData: any, formId: string) => {
        switch (section.sectionType) {
            case 'FORM':
                if (isUpdateAssetPage) {
                    updateInvestment({ ...formData, assetId }, (resp: any) => {
                        redirectTo({
                            redirectionUrl: -1
                        });
                    }, (err: any) => {
                        alert(err.message);
                    });
                } else if (isEditTxnPage) {
                    updateTransaction({ ...formData, transactionId: +transactionId, assetId }, (resp: any) => {
                        redirectTo({
                            redirectionUrl: -1
                        });
                    }, (err: any) => {
                        alert(err.message);
                    });
                } else {
                    addInvestment(formData, (resp: any) => {
                        redirectTo({
                            redirectionUrl: `/asset/${formData.type}/txndetails/${resp.id}`
                        });
                    }, (err: any) => {
                        alert(err.message);
                    });
                }
                break;
            default:
                // do nothing.
                break;
        }
    }

    return (
        <>
            <AllSections {...newPageConfig}
                handleClick={(section: any, formData: any, formId: string) => handleClick(section, formData, formId)}
            />
        </>
    );
};

export default AddUpdateAssetPage;