import React, { useEffect, useState } from 'react';
import AllSections from '../../components/AllSections/AllSections';
import { DashboardService } from '../../services/dashboard.service';
import './DashboardPage.css';
import useRedirect from '../../hooks/useRedirection';

const DashboardPage: React.FC<any> = ({
    date = new Date().getTime(),
    noResultsMessage = 'No Account Found',
    noResultHelpText = 'Click to add new account',
    isViewAccounts = true,
    isViewSummary = true,
    redirectionUrl = '/add-asset'
}) => {
    const [dashboardData, setDashboardData] = useState<any>(null);
    const { redirectTo } = useRedirect();

    const fetchData = async () => {
        try {
            const [dashboardResp]: any = await Promise.allSettled([
                DashboardService.getDashboardData({ isViewAccounts, isViewSummary, date }),
            ]);
            setDashboardData(dashboardResp.value.data);
        } catch (error) {
            setDashboardData({});
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (!dashboardData) {
        return (
            <div className="skeleton">
                <div className="skeleton-header"></div>
                <div className="skeleton-row">
                    <div className="skeleton-card"></div>
                    <div className="skeleton-card"></div>
                </div>
                <div className="skeleton-row">
                    <div className="skeleton-card"></div>
                    <div className="skeleton-card"></div>
                </div>
            </div>
        );
    }

    return (
        <>
            {dashboardData && dashboardData?.sections?.length ? (
                <AllSections {...dashboardData} />
            ) : (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 120px)' }}>
                    <h6 className='mb-4'>{noResultsMessage}</h6>
                    <button className='btn btn-primary' onClick={() => redirectTo({ redirectionUrl })}>
                        {noResultHelpText}
                    </button>
                </div>
            )}
        </>
    );
};

export default DashboardPage;