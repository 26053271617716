import { AssetsManagerService } from "../services/assets-manager.service";

export default function useAssetManager() {

    const addInvestment = async (payload: any, onSuccess: Function, onError: Function) => {
        try {
            let resp = await AssetsManagerService.addInvestment(payload);
            onSuccess(resp);
        } catch (error) {
            onError(error);
        }
    };

    const updateTransaction = async (payload: any, onSuccess: Function, onError: Function) => {
        try {
            let resp = await AssetsManagerService.updateTransaction(payload);
            onSuccess(resp);
        } catch (error) {
            onError(error);
        }
    }

    const updateInvestment = async (payload: any, onSuccess: Function, onError: Function) => {
        try {
            let resp = await AssetsManagerService.updateInvestment(payload);
            onSuccess(resp);
        } catch (error) {
            onError(error);
        }
    }

    const deleteInvestment = async (assetId: string, onSuccess: Function, onError: Function) => {
        try {
            let resp = await AssetsManagerService.deleteInvestment(assetId);
            onSuccess(resp);
        } catch (error) {
            onError(error);
        }
    }

    const deleteTransaction = async (assetId: string, transactionId: string, onSuccess: Function, onError: Function) => {
        try {
            let resp = await AssetsManagerService.deleteTransaction(assetId, transactionId);
            onSuccess(resp);
        } catch (error) {
            onError(error);
        }
    }

    const getInvestments = async () => {
        try {
            return await AssetsManagerService.getInvestment();
        } catch (error) {
            return [];
        }
    }

    return { addInvestment, updateTransaction, updateInvestment, deleteInvestment, deleteTransaction, getInvestments };
}