import React from 'react';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import CircularProgress from '@mui/joy/CircularProgress';
import Typography from '@mui/joy/Typography';
import SvgIcon from '@mui/joy/SvgIcon';
import CardOverflow from '@mui/joy/CardOverflow';
import AspectRatio from '@mui/joy/AspectRatio';
import Chip from '@mui/joy/Chip';
import IconButton from '@mui/joy/IconButton';
import DeleteOutlineOutlined from '@mui/icons-material/DeleteOutlineOutlined';
import { formatCamelCaseString, formatCurrency, formatDate } from '../../utils/common.util';
import Link from '@mui/joy/Link';

const InfoCard: React.FC<any> = (props: any) => {
    const { title, description, description1, color, imageUrl, handleClick, style, highlightedCard, highlightedCardAttr, cardHeadingColor, isUpIcon, isDownIcon, count, isInteractiveCard } = props;
    if (highlightedCard) {
        return <Card variant="solid" {...highlightedCardAttr} invertedColors sx={{ ...style }} onClick={() => handleClick && handleClick(props)}>
            <CardContent orientation="horizontal">
                {(isUpIcon || isDownIcon) && <CircularProgress size="lg" determinate value={20}>

                    {isUpIcon && <SvgIcon>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
                            />
                        </svg>
                    </SvgIcon>}

                    {isDownIcon && <SvgIcon>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21.75 6L15 12.75l-4.306-4.307a11.95 11.95 0 00-5.814 5.519L2.14 15.15m0 0l5.94 2.28m-5.94-2.28l2.28-5.941"
                            />
                        </svg>
                    </SvgIcon>}

                </CircularProgress>}
                <CardContent>
                    <Typography level="title-md">{title}</Typography>
                    <Typography textColor={cardHeadingColor} level="h2">{description}</Typography>
                    {props.description1 && <Typography textColor='white' level="body-md">{props.description1}</Typography>}
                    {props.description2 && <Typography textColor='white' level="body-md">{props.description2}</Typography>}
                    {props.description3 && <Typography textColor='white' level="body-md">{props.description3}</Typography>}
                    {props.description4 && <Typography textColor='white' level="body-md">{props.description4}</Typography>}
                    {props.description5 && <Typography textColor='white' level="body-md">{props.description5}</Typography>}
                    {props.description6 && <Typography textColor='white' level="body-md">{props.description6}</Typography>}
                    {props.description7 && <Typography textColor='white' level="body-md">{props.description7}</Typography>}
                </CardContent>
            </CardContent>
            {/* <CardActions>
                <Button variant="soft" size="sm" onClick={(e) => handleClick && handleClick(props)}>
                    View details
                </Button>
                <Button variant="solid" size="sm">
                    See breakdown
                </Button>
            </CardActions> */}
        </Card>
    } else if (isInteractiveCard) {
        return (
            <Card sx={{
                marginBottom: 2
            }}>
                <CardContent>
                    <Typography
                        level="body-xs"
                        color={isDownIcon ? 'danger' : 'primary'}
                        // startDecorator={
                        //     <React.Fragment>
                        //         {props.isBillIcon && <SvgIcon>
                        //             <svg
                        //                 xmlns="http://www.w3.org/2000/svg"
                        //                 fill="none"
                        //                 viewBox="0 0 24 24"
                        //                 strokeWidth={1.5}
                        //                 stroke="currentColor"
                        //             >
                        //                 <path
                        //                     strokeLinecap="round"
                        //                     strokeLinejoin="round"
                        //                     d="M8.25 3v1.5h7.5V3m-7.5 0a2.25 2.25 0 00-2.25 2.25v15l1.6-1.2a1.2 1.2 0 011.5 0l1.25.937a1.2 1.2 0 001.5 0l1.25-.937a1.2 1.2 0 011.5 0l1.25.937a1.2 1.2 0 001.5 0l1.6-1.2v-15A2.25 2.25 0 0015.75 3m-7.5 0h7.5M9 9.75h6m-6 3h6m-6 3h3"
                        //                 />
                        //             </svg>
                        //         </SvgIcon>}

                        //         {isUpIcon && <SvgIcon>
                        //             <svg
                        //                 xmlns="http://www.w3.org/2000/svg"
                        //                 fill="none"
                        //                 viewBox="0 0 24 24"
                        //                 strokeWidth={1.5}
                        //                 stroke="currentColor"
                        //             >
                        //                 <path
                        //                     strokeLinecap="round"
                        //                     strokeLinejoin="round"
                        //                     d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
                        //                 />
                        //             </svg>
                        //         </SvgIcon>}

                        //         {isDownIcon && <SvgIcon>
                        //             <svg
                        //                 xmlns="http://www.w3.org/2000/svg"
                        //                 fill="none"
                        //                 viewBox="0 0 24 24"
                        //                 strokeWidth={1.5}
                        //                 stroke="currentColor"
                        //             >
                        //                 <path
                        //                     strokeLinecap="round"
                        //                     strokeLinejoin="round"
                        //                     d="M21.75 6L15 12.75l-4.306-4.307a11.95 11.95 0 00-5.814 5.519L2.14 15.15m0 0l5.94 2.28m-5.94-2.28l2.28-5.941"
                        //                 />
                        //             </svg>
                        //         </SvgIcon>}
                        //     </React.Fragment>
                        // }
                        endDecorator={
                            <>
                                {(props.bankName !== props.accountType.label) && <Chip component="span" size="sm" variant="outlined">
                                    {props.bankName}
                                </Chip>}
                                {props.paymentFrequency && <Chip
                                    variant="outlined"
                                    size="sm"
                                >
                                    {formatCamelCaseString(props.paymentFrequency)}
                                </Chip>}
                                {props.frequencyStr && <Chip
                                    variant="outlined"
                                    size="sm"
                                    sx={{ marginLeft: 0.5 }}
                                >
                                    {props.frequencyStr}
                                </Chip>}
                            </>
                        }
                    >
                        <Chip component="span" size="sm" variant="outlined" color="primary">
                            {props.accountType?.label}
                        </Chip>
                    </Typography>
                    {props.cta2 && <IconButton
                        aria-label="bookmark Bahamas Islands"
                        variant="plain"
                        color="neutral"
                        size="sm"
                        sx={{ position: 'absolute', zIndex: 999, top: '0.875rem', right: '0.5rem' }}
                    >
                        <DeleteOutlineOutlined onClick={() => {
                            props.onItemCtaClick && props.onItemCtaClick(props.cta2, props);
                        }} />
                    </IconButton>}
                    {props.accountNumber && <Link
                        color="neutral"
                        textColor="text.primary"
                        overlay
                        // endDecorator={<ArrowOutwardIcon />}
                        sx={{ fontWeight: 'md' }}
                    >
                        {props.accountNumber}
                    </Link>}

                    <Typography
                        level="title-lg"
                        sx={{ mt: 1, fontWeight: 'xl' }}
                        endDecorator={
                            props.transactions && props.showTransactionCount && <Chip size="sm" variant="soft" >
                                {props.transactions.length} Transaction{props.transactions.length > 1 ? 's' : ''}
                            </Chip>
                        }
                    >
                        {formatCurrency(props.amount)}
                    </Typography>
                    {props.customerName && <Typography level="title-sm">
                        {props.customerName}
                    </Typography>}
                    {props.note && <Typography level="body-sm">
                        {props.note}
                    </Typography>}
                    {props.dueDate && <Typography level="body-sm">
                        Maturity Date: {formatDate(props.dueDate, 'dd MMM')}
                    </Typography>}
                    {props.transactions?.length && <Chip
                        variant="soft"
                        size="sm"
                    >
                        {props.transactions.length} Transaction{props.transactions.length > 1 ? 's' : ''}
                    </Chip>}
                </CardContent>
                <CardOverflow>
                    {props.cta1 && <Button
                        onClick={() => {
                            props.onItemCtaClick && props.onItemCtaClick(props.cta1, props);
                        }}
                        variant="solid" color="primary" size="md"
                    >
                        {props.cta1.ctaText}
                    </Button>}
                </CardOverflow>
            </Card>
            // <Card
            //     variant="plain"
            //     // invertedColors
            //     color="primary"
            //     sx={{
            //         border: '1px solid lightgray',
            //         marginBottom: 2
            //     }}>
            //     <div>
            //         <Chip
            //             variant="outlined"
            //             size="sm"
            //             sx={{
            //                 marginBottom: 1,
            //                 marginRight: 1
            //             }}>
            //             {props.accountType.label}
            //         </Chip>
            //         {props.bankName !== props.accountType.label && <Chip
            //             variant="outlined"
            //             size="sm"
            //             sx={{
            //                 marginBottom: 1,
            //                 marginRight: 1
            //             }}>
            //             {props.bankName}
            //         </Chip>}
            //         {props.paymentFrequency && <Chip
            //             variant="outlined"
            //             size="sm"
            //             sx={{
            //                 marginBottom: 1,
            //                 marginRight: 1
            //             }}>
            //             {formatCamelCaseString(props.paymentFrequency)}
            //         </Chip>}
            //         <Typography level="body-sm">{props.accountNumber}</Typography>
            //         <Typography level="title-lg">{props.customerName}</Typography>
            //         <IconButton
            //             aria-label="bookmark Bahamas Islands"
            //             variant="plain"
            //             color="neutral"
            //             size="sm"
            //             sx={{ position: 'absolute', top: '0.875rem', right: '0.5rem' }}
            //         >
            //             {props.cta2 && <DeleteOutlineOutlined onClick={() => {
            //                 props.onItemCtaClick && props.onItemCtaClick(props.cta2, props);
            //             }} />}
            //         </IconButton>
            //     </div>
            //     <CardContent orientation="horizontal">
            //         <div>
            //             <Typography sx={{ fontSize: 'lg', fontWeight: 'md' }}>{formatCurrency(props.amount)}</Typography>
            //         </div>
            //         {props.cta1 && <Button
            //             onClick={() => {
            //                 props.onItemCtaClick && props.onItemCtaClick(props.cta1, props);
            //             }}
            //             variant="soft"
            //             size="md"
            //             color="primary"
            //             aria-label="Explore Bahamas Islands"
            //             sx={{ ml: 'auto', alignSelf: 'center', fontWeight: 600 }}
            //         >
            //             {props.cta1.ctaText}
            //         </Button>}
            //     </CardContent>
            // </Card >
        );
    } return <Card variant="outlined" sx={{ marginBottom: 1 }}>
        {imageUrl && <CardOverflow>
            <AspectRatio ratio="1" sx={{ width: 90 }}>
                <img
                    src={imageUrl}
                    loading="lazy"
                    alt=""
                />
            </AspectRatio>
        </CardOverflow>}
        <CardContent>
            {props.tag && <Chip component="span" size="sm" variant="soft" color="success">
                {props.tag}
            </Chip>}
            <Typography textColor={`${cardHeadingColor}.plainColor` || "primary.plainColor" || color} sx={{ fontWeight: 'md' }}>
                {title}
            </Typography>
            <Typography level="title-md">
                {description}
            </Typography>
            {count && <Typography level="body-sm">
                {count} Account{count > 1 ? 's' : ''}
            </Typography>}
            {props.cta && <CardActions>
                <Button variant="soft" size="sm" onClick={() => handleClick && handleClick(props)}>
                    {props.cta}
                </Button>
            </CardActions>}
        </CardContent>
        {/* <CardOverflow
            variant="soft"
            color="primary"
            sx={{
                px: 0.2,
                // writingMode: 'vertical-rl',
                justifyContent: 'center',
                fontSize: 'xs',
                fontWeight: 'xl',
                letterSpacing: '1px',
                textTransform: 'uppercase',
                borderLeft: '1px solid',
                borderColor: 'divider',
            }}
        >
            {count}
        </CardOverflow> */}
    </Card>

};

export default InfoCard;
