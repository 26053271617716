import React, { useEffect, useState } from 'react';
import './ViewAssetsPage.css';
import useRedirect from '../../hooks/useRedirection';
import { AssetsManagerService } from '../../services/assets-manager.service';
import { useParams } from 'react-router';
import useAssetManager from '../../hooks/useAssetManager';
import { ROUTES } from '../../constants/routes.constant';
import InfoCard from '../../components/InfoCard/InfoCard';
import useRoute from '../../hooks/useRoute';
import { toastService } from '../../services/toast.service';
import PaymentFrequency from '../PaymentFrequency/PaymentFrequency';

const ViewAssetsPage: React.FC<any> = () => {

    const { redirectTo } = useRedirect();
    const { assetType, assetId } = useParams<any>();
    const [assets, setAssets] = useState<any>([]);
    const [paymentFrequency, setPaymentFrequency] = useState<any>(null);
    const { deleteInvestment } = useAssetManager();
    const { queryParams } = useRoute();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [assetsResp]: any = await Promise.allSettled([
                    AssetsManagerService.getInvestment({ assetType, assetId, investmentType: queryParams?.investmentType }),
                ]);
                setAssets(assetsResp.value.investments || []);
                setPaymentFrequency(assetsResp.value.paymentToBeMade || []);
            } catch (error) {
                setAssets([]);
            }
        };

        fetchData();
    }, []);

    const handleClick = (cta, asset) => {
        const { id } = asset;
        if (cta.isDelete) {
            confirm('Are you sure you want to delete this asset?') &&
                deleteInvestment(id, (resp: any) => {
                    toastService.showSuccessToast({ message: resp.message });
                    assets.splice(assets.findIndex((item: any) => item.id === id), 1);
                    setAssets([...assets]);
                }, (err: any) => {
                    alert(err.message);
                });
        } else {
            redirectTo(cta, false, cta.queryParams);
        }
    }

    return (
        <>
            <button className='btn btn-primary w-100' onClick={() => redirectTo({ redirectionUrl: ROUTES.ADD_ASSET.path }, false, { type: assetType })}>Add New Account</button>

            <PaymentFrequency data={paymentFrequency} />

            <div className='mt-3'>
                {assets?.map((asset: any, index: number) => {
                    return <InfoCard
                        isInteractiveCard={true}
                        key={index}
                        onItemCtaClick={(cta, asset) => {
                            handleClick(cta, asset);
                        }}
                        {...asset}
                    />
                })}
            </div>
        </>
    );
};

export default ViewAssetsPage;