import AddUpdateAssetTxn from "../pages/AddUpdateAssetTxn/AddUpdateAssetTxn";
import AddUpdateAsset from "../pages/AddUpdateAsset/AddUpdateAsset";
import Dashboard from "../pages/Dashboard/Dashboard";
import Login from "../pages/Login/Login";
import Menu from "../pages/Menu/Menu";
import Profile from "../pages/Profile/Profile";
import Search from "../pages/Search/Search";
import TransactionDetails from "../pages/TransactionDetails/TransactionDetails";
import VerifyOtp from "../pages/VerifyOtp/VerifyOtp";
import ViewAssets from "../pages/ViewAssets/ViewAssets";
import AddUpdateSpend from "../pages/AddUpdateSpend/AddUpdateSpend";
import ViewSpends from "../pages/ViewSpends/ViewSpends";
import AddUpdateEarnings from "../pages/AddUpdateEarnings/AddUpdateEarnings";
import Passbook from "../pages/Passbook/Passbook";
import SignUp from "../pages/SignUp/SignUp";

export interface Route {
    id: string;
    path: string;
    authReq: boolean;
    redirectTo: string;
    component?: React.ComponentType<any>; // Assuming your components accept any props
}

export const ROUTES: { [key: string]: Route } = {
    DASHBOARD: {
        id: 'DASHBOARD',
        path: '/dashboard',
        authReq: true,
        redirectTo: '',
        component: Dashboard
    },
    ADD_ASSET: {
        id: 'ADD_ASSET',
        path: '/add-asset',
        authReq: true,
        redirectTo: '',
        component: AddUpdateAsset
    },
    ADD_TRANSACTION: {
        id: 'ADD_TRANSACTION',
        path: '/add-txn',
        authReq: true,
        redirectTo: '',
        component: AddUpdateAssetTxn
    },
    UPDATE_TRANSACTION: {
        id: 'UPDATE_TRANSACTION',
        path: '/update-txn',
        authReq: true,
        redirectTo: '',
        component: AddUpdateAssetTxn
    },
    UPDATE_ASSET: {
        id: 'UPDATE_ASSET',
        path: '/update-asset',
        authReq: true,
        redirectTo: '',
        component: AddUpdateAsset
    },
    VIEW_ASSET_DETAILS: {
        id: 'VIEW_ASSET_DETAILS',
        path: '/asset/:assetType/view',
        authReq: true,
        redirectTo: '',
        component: ViewAssets
    },
    VIEW_ALL_ASSET_DETAILS: {
        id: 'VIEW_ALL_ASSET_DETAILS',
        path: '/asset/view',
        authReq: true,
        redirectTo: '',
        component: ViewAssets
    },
    VIEW_ALL_SPENDS: {
        id: 'VIEW_ALL_SPENDS',
        path: '/asset/view/spend',
        authReq: true,
        redirectTo: '',
        component: ViewSpends
    },
    ADD_SPEND: {
        id: 'ADD_SPEND',
        path: '/add-spend',
        authReq: true,
        redirectTo: '',
        component: AddUpdateSpend
    },
    EDIT_SPEND: {
        id: 'EDIT_SPEND',
        path: '/edit-spend',
        authReq: true,
        redirectTo: '',
        component: AddUpdateSpend
    },
    ADD_EARNINGS: {
        id: 'ADD_EARNINGS',
        path: '/add-earnings',
        authReq: true,
        redirectTo: '',
        component: AddUpdateEarnings
    },
    EDIT_EARNING: {
        id: 'EDIT_EARNING',
        path: '/edit-earning',
        authReq: true,
        redirectTo: '',
        component: AddUpdateEarnings
    },
    PASSBOOK: {
        id: 'PASSBOOK',
        path: '/passbook',
        authReq: true,
        redirectTo: '',
        component: Passbook
    },
    TRANSACTION_DETAILS: {
        id: 'TRANSACTION_DETAILS',
        path: '/asset/:assetType/txndetails/:assetId',
        authReq: true,
        redirectTo: '',
        component: TransactionDetails
    },
    SEARCH: {
        id: 'SEARCH',
        path: '/search',
        authReq: true,
        redirectTo: '',
        component: Search,
    },
    MENU: {
        id: 'MENU',
        path: '/menu',
        authReq: true,
        redirectTo: '',
        component: Menu,
    },
    PROFILE: {
        id: 'PROFILE',
        path: '/profile',
        authReq: true,
        redirectTo: '',
        component: Profile,
    },
    LOGIN: {
        id: 'LOGIN',
        path: '/login',
        authReq: false,
        redirectTo: '',
        component: Login,
    },
    SIGNUP: {
        id: 'SIGNUP',
        path: '/signup',
        authReq: false,
        redirectTo: '',
        component: SignUp,
    },
    VERIFY_OTP: {
        id: 'VERIFY_OTP',
        path: '/verify-otp',
        authReq: false,
        redirectTo: '',
        component: VerifyOtp,
    }
}