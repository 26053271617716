import { EventEmitter } from 'events';

class ModalService {
    eventEmitter: EventEmitter;

    constructor() {
        this.eventEmitter = new EventEmitter();
    }

    showModal(props: any) {
        this.eventEmitter.emit('showModal', props);
    }

    closeModal() {
        this.eventEmitter.emit('closeModal');
    }

}

export const modalService = new ModalService();