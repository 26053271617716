import ViewAssetsPage from './ViewAssetsPage';

const ViewAssets: React.FC<any> = ({ pageConfig }) => {

    return (
        <div className="ion-padding">
            <ViewAssetsPage {...pageConfig} />
        </div>
    )
}
export default ViewAssets;