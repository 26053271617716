import { GoogleAuthProvider, PhoneAuthProvider, RecaptchaVerifier, createUserWithEmailAndPassword, getAuth, indexedDBLocalPersistence, initializeAuth, onAuthStateChanged, signInWithCredential, signInWithEmailAndPassword, signInWithPhoneNumber, signInWithPopup } from "firebase/auth";
import { CustomError } from "../Error";
import { setUserDetails } from "../managers/user.manager";
import { auth } from "../Firebase";
import { FirebaseService } from "./firebase.service";

declare let window: any;

export class FirebaseAuthService {

    static initializeRecaptchaVerifier = (buttonId: string) => {
        window.recaptchaVerifier = new RecaptchaVerifier(buttonId, {
            'size': 'invisible'
        }, auth);
    }

    static signUpWithEmailFb(email: string, password: string) {
        return new Promise((resolve, reject) => {
            createUserWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    const user = userCredential.user;
                    resolve(user);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static signInWithPhoneNumber(phoneNumber: string) {
        const appVerifier = window.recaptchaVerifier;
        return new Promise((resolve, reject) => {
            signInWithPhoneNumber(auth, phoneNumber, appVerifier)
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(new CustomError(error));
                });
        });
    }

    static verifyOtp(verificationId: string, verificationCode: string) {
        return new Promise((resolve, reject) => {
            const credential = PhoneAuthProvider.credential(verificationId, verificationCode);
            signInWithCredential(auth, credential)
                .then((result: any) => {
                    const user = result.user;
                    resolve(user);
                })
                .catch((error: any) => {
                    reject(new CustomError(error));
                });
        });
    }

    static signInWithEmailAndPassword(email: string, password: string) {
        return new Promise((resolve, reject) => {
            signInWithEmailAndPassword(auth, email, password)
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(new CustomError(error));
                });
        });
    }

    static signUpWithEmailAndPassword(email: string, password: string) {
        return new Promise((resolve, reject) => {
            createUserWithEmailAndPassword(auth, email, password)
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(new CustomError(error));
                });
        });
    };

    static signInWithGoogle() {
        return new Promise((resolve, reject) => {
            const provider = new GoogleAuthProvider();
            signInWithPopup(auth, provider)
                .then((result: any) => {
                    resolve(result);
                })
                .catch((error: any) => {
                    reject(new CustomError(error));
                });
        });
    }

    static loginWithUID(uid: string) {
        return new Promise(async (resolve, reject) => {
            if (await FirebaseService.checkIfUidPresentInDb(uid)) {
                resolve({
                    uid
                });
            } else {
                reject(new CustomError({ message: 'User not found.' }));
            }
        });
    }

    static initAuthStateListener() {
        return new Promise((resolve, reject) => {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    // User is signed in
                    setUserDetails(user);
                    console.log('User is signed in');
                    resolve(true);
                } else {
                    // No user is signed in
                    console.log('User is not signed in');
                    resolve(false);
                }
            });
        })
    }
}