import React, { useEffect, useState } from 'react';
import { FIREBASE_DB_CONSTANTS } from './constants/firebase.constant';
import { DataService } from './services/data.service';
import { FirbaseDbService } from './services/FirebaseDB.service';
import FirebaseAppWrapper from './FirebaseAppWrapper';
import Toast from './components/Toast/Toast';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { getCurrentRouteDetails } from './utils/routes.util';
import { Redirect, withRouter } from 'react-router-dom';
import { IonContent, IonIcon, IonLabel, IonPage, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { LocalStorageUtils } from './utils/localStorage.util';
import { ROUTES } from './constants/routes.constant';
import PrivateRoute from './GuardedRoute';
import { isUserLoggedIn } from './managers/user.manager';
import Modal from './components/Modal/Modal';
import { modalService } from './services/modal.service';
import { LoaderService } from './services/loader.service';
import KeyboardWrapper from './KeyboardWrapper';

const RootWrapper: React.FC<any> = (): any => {
    const [toolbarIcons, setToolbarIcons] = useState(JSON.parse(LocalStorageUtils.getValueFromLocalStorage(FIREBASE_DB_CONSTANTS.TOOLBAR_ICONS_CONFIG.dbpath))?.icons || []);
    const [pageConfig, setPageConfig] = useState(JSON.parse(LocalStorageUtils.getValueFromLocalStorage(FIREBASE_DB_CONSTANTS.PAGE_CONFIG.dbpath)));
    const currentRouteDetails = getCurrentRouteDetails();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalProps, setModalProps] = useState<any>({});
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

    const shouldShowTabs = pageConfig?.[currentRouteDetails?.id]?.showTabs;

    useEffect(() => {
        (async () => {
            try {
                let [constantsData, toolbarIconConfig, pageConfigResp] = await Promise.all([
                    FirbaseDbService.getAllConstants(),
                    FirbaseDbService.getToolbarIconsConfig(),
                    FirbaseDbService.getPageConfig()
                ]);
                constantsData && Object.keys(constantsData).map((key) => {
                    DataService.set(
                        FIREBASE_DB_CONSTANTS.CONSTANTS.dbpath + '/' + key,
                        constantsData[key]
                    );
                });
                toolbarIconConfig && setToolbarIcons(toolbarIconConfig.icons);
                pageConfigResp && setPageConfig(pageConfigResp);
            } catch (error) {
                // error in firebase data.
                // Do nothing.
            }
        })();
    }, []);

    useEffect(() => {
        modalService.eventEmitter.on('showModal', (props) => {
            setIsModalOpen(true);
            setModalProps(props);
        });
        modalService.eventEmitter.on('closeModal', () => {
            setIsModalOpen(false);
        });
        LoaderService.hide();
    }, []);

    if (!pageConfig) return null;

    return (
        <div>
            <KeyboardWrapper handleKeyboardOpen={(e: any) => {
                setIsKeyboardOpen(e);
            }} />
            <FirebaseAppWrapper />
            {isModalOpen && <Modal pageConfig={pageConfig} modalProps={modalProps} component={modalProps.component} />}
            <Toast />
            <IonPage placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                <Header page={currentRouteDetails?.id} />
                <IonContent style={pageConfig?.[currentRouteDetails?.id]?.bodyBgStyle} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    {shouldShowTabs && (<IonTabs>
                        <IonRouterOutlet style={{ overflowY: "scroll", paddingBottom: isKeyboardOpen && 250 }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            {Object.keys(ROUTES).map((key: string, index: number) => {
                                let route = ROUTES[key as keyof typeof ROUTES];
                                return (
                                    <PrivateRoute pageConfig={pageConfig} component={route.component} authReq={isUserLoggedIn(route)} path={route.path} key={index} />
                                )
                            })}
                        </IonRouterOutlet>
                        <IonTabBar color="translucent" slot="bottom" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            {toolbarIcons.map((icon: any, index: number) => {
                                const isSelected = currentRouteDetails.path === icon.path;
                                const iconClass = `${icon.className} ${isSelected ? 'selected-icon' : ''}`;

                                return (
                                    <IonTabButton className={iconClass} tab={`tab${index + 1}`} href={icon.path} key={index}>
                                        <IonIcon id={'icon' + index} icon={icon.icon} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                                        <IonLabel placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>{icon.iconLabel}</IonLabel>
                                    </IonTabButton>
                                )
                            })}
                        </IonTabBar>
                    </IonTabs>)}
                    {!shouldShowTabs && (
                        <IonRouterOutlet style={{ overflow: 'scroll', paddingBottom: isKeyboardOpen && 250 }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            {Object.keys(ROUTES).map((key: string, index: number) => {
                                let route = ROUTES[key as keyof typeof ROUTES];
                                return (
                                    <PrivateRoute pageConfig={pageConfig} component={route.component} authReq={isUserLoggedIn(route)} path={route.path} key={index} />
                                )
                            })}
                            <Redirect to="/dashboard" />
                        </IonRouterOutlet>
                    )}
                </IonContent>
                <Footer page={currentRouteDetails?.id} />
            </IonPage>
        </div>
    );
};

export default withRouter(RootWrapper);
