const FirebaseAppWrapper: React.FC = (props: any): any => {

    const { children } = props;

    return (
        <>
            {children}
        </>
    );
};

export default FirebaseAppWrapper;
