import React from 'react';
import './AddUpdateEarningsPage.css';
import AllSections from '../../components/AllSections/AllSections';
import useRedirect from '../../hooks/useRedirection';
import { useLocation } from 'react-router-dom';
import useRoute from '../../hooks/useRoute';
import { ROUTES } from '../../constants/routes.constant';
import useEarningManager from '../../hooks/useEarningManager';

const AddUpdateEarningsPage: React.FC<any> = (pageConfig) => {
    const { addEarning, updateEarning } = useEarningManager();
    const { redirectTo } = useRedirect();
    const location = useLocation();
    const isEditPage = location.pathname.includes('edit-earning');
    const { queryParams: { id } } = useRoute();

    const handleClick = (section: any, formData: any, formId: string) => {
        switch (section.sectionType) {
            case 'FORM':
                if (isEditPage) {
                    updateEarning({ ...formData, id }, (resp: any) => {
                        redirectTo({
                            redirectionUrl: -1
                        });
                    }, (err: any) => {
                        alert(err.message);
                    });
                } else {
                    addEarning(formData, (resp: any) => {
                        redirectTo({
                            redirectionUrl: ROUTES.PASSBOOK.path
                        });
                    }, (err: any) => {
                        alert(err.message);
                    });
                }
                break;
            default:
                // do nothing.
                break;
        }
    }

    return (
        <>
            {
                isEditPage ?
                    <AllSections {...pageConfig.EDIT_EARNING}
                        handleClick={(section: any, formData: any, formId: string) => handleClick(section, formData, formId)}
                    /> : <AllSections {...pageConfig.ADD_EARNINGS}
                        handleClick={(section: any, formData: any, formId: string) => handleClick(section, formData, formId)}
                    />
            }
        </>
    );
};

export default AddUpdateEarningsPage;