import { LOCAL_STORAGE_CONSTANT } from "../constants/localStorage.constant";
import { getAccumulatedAmount, getInvestmentId, mapInvestmentItems, mapInvestmentPayload, mapInvestmentTransaction } from "../mapper/investment.mapper";
import { LocalStorageUtils } from "../utils/localStorage.util";
import { CommonService } from "./common.service";
import { FirbaseDbService } from "./FirebaseDB.service";

export const AssetsManagerService = {
    // Get account type object
    getAccountType: async (key: string): Promise<any> => {
        const constantData = await FirbaseDbService.getAllConstants();
        const accountType = constantData?.ACCOUNT_TYPE?.find((account: any) => account.value === key);
        return accountType;
    },

    // Get account sub type
    getAccountSubTypes: async (): Promise<any> => {
        const constantData = await FirbaseDbService.getAllConstants();
        return constantData?.SUB_TYPES;
    },

    // Get account sub type
    getPaymentFrequencyTypes: async (): Promise<any> => {
        const constantData = await FirbaseDbService.getAllConstants();
        return constantData?.PAYMENT_FREQUENCY;
    },

    // Get particular investment
    getInvestment: ({ assetType, assetId, investmentType }: any = {}) => {
        return new Promise(async (resolve, reject) => {
            let investments: any = await CommonService.getRawData('investments');
            const filteredInvestments = Object.values(investments).filter((value: any) => {
                return (assetType ? value.type === assetType : true) && (assetId ? value.id === assetId : true) && (investmentType ? value.subType === investmentType : true);
            });
            const investmentsWithAccountType = await Promise.all(filteredInvestments.map(async (investment: any) => {
                const accountType = await AssetsManagerService.getAccountType(investment.type);
                return { ...investment, accountType };
            }));
            resolve(await mapInvestmentItems(investmentsWithAccountType));
        });
    },

    // Merge investments based on type and calculate total amount for each type
    getMergedAssetDetails: (dateInTs: number) => {
        return new Promise(async (resolve, reject) => {
            let investments: any = await CommonService.getRawData('investments');
            const subTypes = await AssetsManagerService.getAccountSubTypes();

            const mergedInvestments: any = subTypes.reduce((acc, type) => {
                acc[type.key] = [];
                acc[`${type.key}Count`] = 0;
                acc[`${type.key}Amount`] = 0;
                return acc;
            }, { investedAmountInCurrentMonth: 0, investedAccountsInThisMonth: [], noSubTypeCount: 0, noSubTypeAmount: 0, noSubTypeInvestments: [] });

            Object.values(investments).forEach((investment: any) => {
                const accountType = subTypes.find(type => type.value === investment.subType);
                if (accountType) {
                    const targetArray = mergedInvestments[accountType.key];
                    const existingInvestment = targetArray.find((item: any) => item.type === investment.type);

                    if (existingInvestment) {
                        existingInvestment.amount += getAccumulatedAmount(investment.transactions);
                        existingInvestment.count += 1;
                    } else {
                        targetArray.push({
                            type: investment.type,
                            amount: getAccumulatedAmount(investment.transactions),
                            count: 1
                        });
                    }
                    mergedInvestments[`${accountType.key}Count`] += 1;
                } else {
                    mergedInvestments.noSubTypeInvestments.push({
                        type: investment.type,
                        amount: getAccumulatedAmount(investment.transactions),
                        count: 1
                    });
                    mergedInvestments.noSubTypeCount += 1;
                }
            });

            subTypes.forEach(type => {
                const key = type.key;
                mergedInvestments[`${key}Amount`] = mergedInvestments[key].reduce((acc: number, investment: any) => acc + investment.amount, 0);
                mergedInvestments[key].sort((a: any, b: any) => b.amount - a.amount);
            });

            mergedInvestments.noSubTypeAmount = mergedInvestments.noSubTypeInvestments.reduce((acc: number, investment: any) => acc + investment.amount, 0);
            mergedInvestments.noSubTypeInvestments.sort((a: any, b: any) => b.amount - a.amount);

            if (dateInTs) {
                mergedInvestments.investedAmountInCurrentMonth = Object.values(investments)
                    .filter((investment: any) => investment.subType === 'INVESTMENT')
                    .reduce((acc: number, investment: any) => {
                        const amountInCurrentMonth = investment.transactions
                            .filter((transaction: any) => {
                                const transactionDate = new Date(transaction.date);
                                const filterDate = new Date(dateInTs);
                                return transactionDate.getMonth() === filterDate.getMonth()
                                    && transactionDate.getFullYear() === filterDate.getFullYear()
                                    && (!transaction.isSynced || !Object.keys(transaction).includes('isSynced'));
                            })
                            .reduce((transactionAcc: number, transaction: any) => transactionAcc + transaction.amount, 0);

                        if (amountInCurrentMonth) {
                            const unsyncedTransactions = investment.transactions.filter((transaction: any) => {
                                return (Object.keys(transaction).includes('isSynced') && !transaction.isSynced) || !Object.keys(transaction).includes('isSynced');
                            });
                            if (unsyncedTransactions.length > 0) {
                                mergedInvestments.investedAccountsInThisMonth.push({
                                    ...investment,
                                    transactions: unsyncedTransactions
                                });
                            }
                        }

                        return acc + amountInCurrentMonth;
                    }, 0);
            } else {
                mergedInvestments.investedAmountInCurrentMonth = 0;
            }

            resolve(mergedInvestments);
        });
    },

    // Add investment
    addInvestment: (payload: any = {}) => {
        return new Promise(async (resolve, reject) => {
            let investments: any = await CommonService.getRawData('investments');
            // Check if investment already exists
            const investmentId = payload.investmentId || getInvestmentId(payload);
            const existingInvestment = investments[investmentId];
            if (existingInvestment) {
                existingInvestment.updatedAt = new Date().getTime();
                existingInvestment.transactions.push({
                    id: existingInvestment.transactions.length + 1,
                    createdAt: new Date().getTime(),
                    ...mapInvestmentTransaction(payload)
                });
            } else {
                let _payload = {
                    id: investmentId,
                    ...mapInvestmentPayload(payload),
                    createdAt: new Date().getTime(),
                    transactions: [
                        {
                            id: 1,
                            ...mapInvestmentTransaction(payload)
                        }
                    ]
                }
                investments[investmentId] = _payload;
            }
            try {
                await FirbaseDbService.addDataInFirestoreDb(
                    'users',
                    LocalStorageUtils.getValueFromLocalStorage(LOCAL_STORAGE_CONSTANT.UID),
                    { ...await CommonService.getRawData(), investments }
                );
                resolve({ message: 'Investment added successfully', id: investmentId });
            } catch (error) {
                reject(error);
            }
        });
    },

    // Update investment
    updateInvestment: (payload: any = {}) => {
        const { assetId } = payload;
        return new Promise(async (resolve, reject) => {
            let investments: any = await CommonService.getRawData('investments');
            const investment = investments[assetId];
            if (investment) {
                investments[assetId] = {
                    id: investment.id,
                    updatedAt: new Date().getTime(),
                    ...mapInvestmentPayload(payload)
                };
            }
            try {
                await FirbaseDbService.updateDataInFirestoreDb(
                    'users',
                    LocalStorageUtils.getValueFromLocalStorage(LOCAL_STORAGE_CONSTANT.UID),
                    { ...await CommonService.getRawData(), investments }
                );
                resolve({ message: 'Investment updated successfully' });
            } catch (error) {
                reject(error);
            }
        });
    },

    // Update transaction
    updateTransaction: (payload: any = {}) => {
        return new Promise(async (resolve, reject) => {
            let investments: any = await CommonService.getRawData('investments');
            const investment = investments[payload.assetId];
            if (investment) {
                investment.transactions = investment.transactions.map((transaction: any) => {
                    if (transaction.id === payload.transactionId) {
                        return {
                            id: transaction.id,
                            updatedAt: new Date().getTime(),
                            ...mapInvestmentTransaction(payload)
                        };
                    }
                    return transaction;
                });
            }
            try {
                await FirbaseDbService.updateDataInFirestoreDb(
                    'users',
                    LocalStorageUtils.getValueFromLocalStorage(LOCAL_STORAGE_CONSTANT.UID),
                    { ...await CommonService.getRawData(), investments }
                );
                resolve({ message: 'Investment updated successfully' });
            } catch (error) {
                reject(error);
            }
        });
    },

    // Delete investment
    deleteInvestment: (assetId: string) => {
        return new Promise(async (resolve, reject) => {
            let investments: any = await CommonService.getRawData('investments');
            delete investments[assetId];
            try {
                await FirbaseDbService.addDataInFirestoreDb(
                    'users',
                    LocalStorageUtils.getValueFromLocalStorage(LOCAL_STORAGE_CONSTANT.UID),
                    { ...await CommonService.getRawData(), investments }
                );
                resolve({ message: 'Investment deleted successfully' });
            } catch (error) {
                reject(error);
            }
        });
    },

    // Delete transaction
    deleteTransaction: (assetId: string, transactionId: string) => {
        return new Promise(async (resolve, reject) => {
            let investments: any = await CommonService.getRawData('investments');
            const investment = investments[assetId];
            if (investment) {
                investment.transactions = investment.transactions.filter((transaction: any) => transaction.id !== transactionId);
            }
            try {
                await FirbaseDbService.addDataInFirestoreDb(
                    'users',
                    LocalStorageUtils.getValueFromLocalStorage(LOCAL_STORAGE_CONSTANT.UID),
                    { ...await CommonService.getRawData(), investments }
                );
                resolve({ message: 'Transaction deleted successfully' });
            } catch (error) {
                reject(error);
            }
        });
    }

}