import React, { useEffect, useState } from 'react';
import { FIREBASE_DB_CONSTANTS } from '../../constants/firebase.constant';
import { FirbaseDbService } from '../../services/FirebaseDB.service';
import { LocalStorageUtils } from '../../utils/localStorage.util';
import styles from './Footer.module.css';

export interface FooterProps {
    page: string;
}

const Footer: React.FC<FooterProps> = ({ page }) => {
    try {
        const [footerConfig, setFooterConfig] = useState<any>((page && JSON.parse(LocalStorageUtils.getValueFromLocalStorage(FIREBASE_DB_CONSTANTS.FOOTER_CONFIG.dbpath))?.[page]) || {});

        useEffect(() => {
            const getUpdatedConfig = async () => {
                const updatedHeaderConfig = await FirbaseDbService.getFooterConfig();
                setFooterConfig(updatedHeaderConfig?.[page]);
            };

            getUpdatedConfig();
        });

        if (!footerConfig) return null;

        return (
            <footer className={styles.content__footer}>
                <div className="mb-2 mb-md-0">
                    <span dangerouslySetInnerHTML={{ __html: footerConfig.html }}></span>
                </div>
            </footer>
        );
    } catch (error) {
        return null;
    }
}
export default Footer;
