import React from 'react';
import './AddUpdateSpendPage.css';
import AllSections from '../../components/AllSections/AllSections';
import useAssetManager from '../../hooks/useAssetManager';
import useRedirect from '../../hooks/useRedirection';
import { useLocation } from 'react-router-dom';
import useRoute from '../../hooks/useRoute';
import useSpendManager from '../../hooks/useSpendManager';
import { ROUTES } from '../../constants/routes.constant';

const AddUpdateSpendPage: React.FC<any> = (pageConfig) => {
    const { addSpend, updateSpend } = useSpendManager();
    const { redirectTo } = useRedirect();
    const location = useLocation();
    const isEditPage = location.pathname.includes('edit-spend');
    const { queryParams: { id } } = useRoute();
    const [newPageConfig, setNewPageConfig] = React.useState<any>(null);
    const { getInvestments } = useAssetManager();

    React.useEffect(() => {
        const getConifg = async () => {
            let config = null;
            if (isEditPage) {
                config = pageConfig.EDIT_SPEND;
            } else {
                config = pageConfig.ADD_SPEND;
            }

            const { sections } = config;
            await Promise.all(sections.map(async (section: any) => {
                if (section.sectionType === 'FORM') {
                    await Promise.all(section.FORM_CONFIG.formFields.map(async (field: any) => {
                        if (field.id === 'transactionMadeFrom') {
                            let investments: any = await getInvestments();
                            investments = investments.investments;
                            field.options = [
                                { value: 'none', label: 'Select Investment (If any)' },
                                ...Object.keys(investments).map((key: any) => {
                                    return {
                                        value: investments[key].id,
                                        label: `${investments[key].accountType.label} - ${investments[key].customerName} - ${investments[key].accountNumber}`
                                    }
                                })
                            ];
                        }
                    }));
                }
            }));

            setNewPageConfig(config);
        };

        getConifg();

    }, []);

    const handleClick = (section: any, formData: any, formId: string) => {
        switch (section.sectionType) {
            case 'FORM':
                if (isEditPage) {
                    updateSpend({ ...formData, id }, (resp: any) => {
                        redirectTo({
                            redirectionUrl: -1
                        });
                    }, (err: any) => {
                        alert(err.message);
                    });
                } else {
                    addSpend(formData, (resp: any) => {
                        redirectTo({
                            redirectionUrl: ROUTES.PASSBOOK.path
                        });
                    }, (err: any) => {
                        alert(err.message);
                    });
                }
                break;
            default:
                // do nothing.
                break;
        }
    }

    return (
        <>
            <AllSections {...newPageConfig}
                handleClick={(section: any, formData: any, formId: string) => handleClick(section, formData, formId)}
            />
        </>
    );
};

export default AddUpdateSpendPage;