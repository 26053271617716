export const DataService = {
    data: <any>{},
    get: (key: string) => {
        return DataService.data[key];
    },
    set: (key: string, value: any, maintainValuesInObject: boolean = false) => {
        if (maintainValuesInObject) {
            if (DataService.data[key]) {
                DataService.data[key] = Object.assign({}, DataService.data[key], value);
            } else {
                DataService.data[key] = value;
            }
        } else {
            DataService.data[key] = value;
        }
    }
}