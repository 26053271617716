import ProfilePage from './ProfilePage';

const Profile: React.FC<any> = ({ pageConfig }) => {

    return (
        <div className="ion-padding">
            <ProfilePage pageConfig={pageConfig} />
        </div>
    )
}
export default Profile;