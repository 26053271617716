import { ROUTES } from "../constants/routes.constant";

export const getCurrentRouteDetails = (): any => {
    return Object.values(ROUTES).find((page: any) => matchPaths(page.path, window.location.pathname));
}

function matchPaths(path1: string, path2: string) {
    const path1Parts = path1.split('/');
    const path2Parts = path2.split('/');

    if (path1Parts.length !== path2Parts.length) {
        return false;
    }

    for (let i = 0; i < path1Parts.length; i++) {
        const part1 = path1Parts[i];
        const part2 = path2Parts[i];

        if (part1 !== part2 && !part1.startsWith(':') && !part2.startsWith(':')) {
            return false;
        }
    }

    return true;
}