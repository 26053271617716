import { ENV_CONFIG } from "../config/config";
import { serviceCall } from "./rest-client";

export const UserService = {

    sendCode: (mobileNumber = '') => {
        return serviceCall(ENV_CONFIG.SEND_OTP, 'POST', { data: mobileNumber });
    },

    loginOrRegisterUser: (uid: string, payload: any = {}) => {
        let url = ENV_CONFIG.LOGIN_OR_REGISTER_USER.replace('{0}', uid);
        return serviceCall(url, 'POST', { data: payload });
    },

    getUserDetails: (payload = {}) => {
        let url = ENV_CONFIG.GET_USER_DETAILS;
        // Append payload as query parameters
        const queryParams = new URLSearchParams(payload).toString();
        url += `?${queryParams}`;

        return serviceCall(url, 'GET', { loader: true });
    },

    updateUserDetails: (payload: any = {}, patientid?: string) => {
        let url = ENV_CONFIG.UPDATE_USER_DETAILS;
        if (patientid) {
            url += 'patientid=' + patientid;
        }
        return serviceCall(url, 'PUT', { data: payload });
    },
}