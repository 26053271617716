import React, { useEffect, useState } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { ROUTES } from './constants/routes.constant';
import { FirebaseAuthService } from './services/FirebaseAuth.service';
import Loader from './components/Loader/Loader';
import { IonSpinner } from '@ionic/react';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { LocalStorageUtils } from './utils/localStorage.util';
import { LOCAL_STORAGE_CONSTANT } from './constants/localStorage.constant';
import useRedirect from './hooks/useRedirection';

const PrivateRoute: React.FC<any> = (props: any) => {
    const [authCheckDone, setAuthCheckDone] = useState(true);
    const { redirectTo } = useRedirect();


    useEffect(() => {
        const checkForAuth = async () => {
            try {
                if (LocalStorageUtils.getValueFromLocalStorage(LOCAL_STORAGE_CONSTANT.SSO)) {
                    await FirebaseAuthService.initAuthStateListener();
                }
                setAuthCheckDone(true);
            } catch (error) {
                setAuthCheckDone(true);
            }
        }

        checkForAuth();
    }, []);

    return authCheckDone ? (props.authReq ? (
        <ErrorBoundary>
            <Route path={props.path} exact render={() => <props.component {...props} />}>
            </Route>
        </ErrorBoundary>
    ) : (
        <Redirect to={{ pathname: ROUTES.LOGIN.path }} />
    )) : <div className='body-center'>
        <IonSpinner name="circular" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonSpinner>
    </div>

}

export default PrivateRoute;