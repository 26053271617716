const letters = 'abcdefghijklmnopqrstuvwxyz0123456789'.split('');
const letterColorMap: { [key: string]: string } = {};
export const getRandomColor = () => {
    const letters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let color = "#";
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return `${color}`;
}

letters.forEach(letter => {
    letterColorMap[letter] = getRandomColor();
});

export const LETTER_COLOR_MAP = (letter: any) => letterColorMap[letter.toLowerCase()];


