import AllSections from '../../components/AllSections/AllSections';
import useSignIn from '../../hooks/useSignIn';
import useRedirect from '../../hooks/useRedirection';
import { ROUTES } from '../../constants/routes.constant';
import { toastService } from '../../services/toast.service';
import { FirebaseService } from '../../services/firebase.service';

const SignUpPage: React.FC<any> = ({ pageConfig }) => {

    const { signUpWithEmail } = useSignIn();
    const { redirectTo } = useRedirect();

    return (
        <AllSections {...pageConfig?.SIGNUP} handleClick={(section: any, formData: any) => {
            signUpWithEmail(formData, async (resp: any) => {
                await FirebaseService.loginOrSignUpUser(resp.uid);
                toastService.showSuccessToast({ message: 'Sign up successful.' });
                redirectTo({ redirectionUrl: ROUTES.LOGIN.path });
            }, (err: any) => {
                toastService.showErrorToast({ message: err.message });
            });
        }} />
    );
};

export default SignUpPage;
