export const FIREBASE_DB_CONSTANTS = {
    CONSTANTS: {
        dbpath: 'CONSTANTS',
    },
    ADMIN: {
        dbpath: 'ADMIN',
    },
    TOOLBAR_ICONS_CONFIG: {
        dbpath: 'ADMIN/TOOLBAR_ICONS_CONFIG'
    },
    HEADER_CONFIG: {
        dbpath: 'ADMIN/HEADER_CONFIG'
    },
    FOOTER_CONFIG: {
        dbpath: 'ADMIN/FOOTER_CONFIG'
    },
    PAGE_CONFIG: {
        dbpath: 'ADMIN/PAGE_CONFIG'
    },
}