import { FirebaseAuthService } from "../services/FirebaseAuth.service";
import { LoaderService } from "../services/loader.service";
import { UserService } from "../services/user.service";

export default function useSignIn() {

    const sendOtp = async (payload: any, onSuccess: Function, onError: Function) => {
        try {
            LoaderService.show();
            let { mobile } = payload;
            let resp = await FirebaseAuthService.signInWithPhoneNumber(`+91${mobile}`);
            LoaderService.hide();
            onSuccess(resp);
        } catch (error) {
            LoaderService.hide();
            onError(error);
        }
    };

    const verifyOtp = async (payload: any, onSuccess: Function, onError: Function) => {
        try {
            LoaderService.show();
            let { otp, verificationId } = payload;
            let resp = await FirebaseAuthService.verifyOtp(verificationId, otp);
            LoaderService.hide();
            onSuccess(resp);
        } catch (error) {
            LoaderService.hide();
            onError(error);
        }
    };

    const loginWithEmail = async (payload: any, onSuccess: Function, onError: Function) => {
        try {
            LoaderService.show();
            let { email, password } = payload;
            let resp = await FirebaseAuthService.signInWithEmailAndPassword(email, password);
            LoaderService.hide();
            onSuccess(resp);
        } catch (error) {
            LoaderService.hide();
            onError(error);
        }
    }

    const signUpWithEmail = async (payload: any, onSuccess: Function, onError: Function) => {
        try {
            LoaderService.show();
            let { email, password } = payload;
            let resp = await FirebaseAuthService.signUpWithEmailFb(email, password);
            LoaderService.hide();
            onSuccess(resp);
        } catch (error) {
            LoaderService.hide();
            onError(error);
        }
    }

    const loginWithGoogle = async (onSuccess: Function, onError: Function) => {
        try {
            LoaderService.show();
            let resp = await FirebaseAuthService.signInWithGoogle();
            LoaderService.hide();
            onSuccess(resp);
        } catch (error) {
            LoaderService.hide();
            onError(error);
        }
    }

    const loginWithUID = async (payload: any, onSuccess: Function, onError: Function) => {
        try {
            LoaderService.show();
            let { uid } = payload;
            let resp = await FirebaseAuthService.loginWithUID(uid);
            LoaderService.hide();
            onSuccess(resp);
        } catch (error) {
            LoaderService.hide();
            onError(error);
        }
    }

    return { sendOtp, verifyOtp, loginWithEmail, signUpWithEmail, loginWithGoogle, loginWithUID };
}