import { useEffect, useState } from "react";
import AllSections from "../../components/AllSections/AllSections";
import { DashboardService } from "../../services/dashboard.service";
import { IonSkeletonText } from "@ionic/react";

const SearchPage: React.FC = () => {
    const [searchPageData, setSearchPageData] = useState<any>(null);

    const fetchData = async () => {
        try {
            const [dashboardResp]: any = await Promise.allSettled([
                DashboardService.getDashboardData({ permalink: 'search' })
            ]);
            setSearchPageData(dashboardResp.value.data);
        } catch (error) {
            setSearchPageData({});
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <>
            {searchPageData ? (
                <AllSections {...searchPageData} />
            ) : (
                <>
                    <IonSkeletonText animated style={{ width: '80%', height: '20px', margin: '15px' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonSkeletonText animated style={{ width: '60%', height: '20px', margin: '15px' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    <IonSkeletonText animated style={{ width: '40%', height: '20px', margin: '15px' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                    {/* Add more skeleton lines as needed */}
                </>
            )
            }
        </>
    );
};

export default SearchPage;