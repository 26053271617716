import { useState, useEffect } from 'react';
import useAppDetails from '../../hooks/useAppDetails';
import useRedirect from '../../hooks/useRedirection';
import { LocalStorageUtils } from '../../utils/localStorage.util';
import CustomList from '../../components/CustomList/CustomList';

const MenuPage: React.FC<any> = ({ pageConfig }) => {
  const { redirectTo } = useRedirect();
  const [menu, setMenu] = useState<any>([]);
  const { appVersion, buildNumber, platform } = useAppDetails();

  useEffect(() => {
    const items = JSON.parse(JSON.stringify(pageConfig?.MENU?.list));
    const menu = items?.
      filter((item: any) => {
        return item.platforms?.indexOf(platform) > -1;
      })
      .filter((item: any) => {
        if (item.isUpdateAppLabel) {
          if (buildNumber >= item.currentBuildNumber) {
            // do nothing
          } else {
            return item;
          }
        } else {
          return item;
        }
      })
      .map((item: any) => {
        if (item.isVersionLabel && appVersion && buildNumber) {
          item.title += ' ' + appVersion + ' (' + buildNumber + ')';
        }
        return item;
      })
    setMenu(menu);
  }, []);

  return (
    <>
      <CustomList items={menu} />
      <div className='text-center mt-2'>
        <p onClick={() => {
          LocalStorageUtils.logout();
          redirectTo({ redirectionUrl: '/login' });
        }} className='text-danger' style={{ fontWeight: 'bold' }}>{pageConfig?.MENU?.logoutText}</p>
      </div>
    </>
  );
};

export default MenuPage;
