import { useEffect } from 'react';
import { PushNotifications } from '@capacitor/push-notifications';

export default function PushNotificationsContainer(props?: any) {
    useEffect(() => {
        PushNotifications.checkPermissions().then((res) => {
            if (res.receive !== 'granted') {
                PushNotifications.requestPermissions().then((res) => {
                    if (res.receive === 'denied') {
                        // 'Push Notification permission denied'
                    } else {
                        register(); // 'Push Notification permission granted'
                    }
                });
            } else {
                register();
            }
        });
    }, []);

    const register = () => {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
    }

    return (
        <>
            {props.children}
        </>
    )
}