import React, { useState } from 'react';
import './ViewSpendsPage.css';
import useRedirect from '../../hooks/useRedirection';
import { ROUTES } from '../../constants/routes.constant';
import DashboardPage from '../Dashboard/DashboardPage';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import CustomTable from '../../components/CustomTable/CustomTable';

const ViewSpendsPage: React.FC<any> = () => {
    const currentYear = dayjs();
    const getDefaultDate = dayjs(new Date());
    const { redirectTo } = useRedirect();
    const [selectedDate, setSelectedDate] = useState<any>(new Date(getDefaultDate.format()).getTime());

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    className="w-100 mt-3"
                    views={['month', 'year']}
                    label="Select Month"
                    openTo="month"
                    maxDate={currentYear}
                    defaultValue={getDefaultDate}
                    onAccept={(date) => {
                        setSelectedDate(new Date(date.format()).getTime());
                    }}
                />
            </LocalizationProvider>
            <div className='mb-3'>
                <DashboardPage
                    date={selectedDate}
                    isViewAccounts={false}
                    redirectionUrl={ROUTES.ADD_SPEND.path}
                    noResultsMessage='No Transactions Found'
                    noResultHelpText='Click to add new transaction'
                    key={selectedDate}
                />
            </div>
            <p className='mt-2'>*Cash Expense & Sync transactions are not included in calculations.</p>
            <Typography variant="h6" >MONTHLY BREAK-UP</Typography>
            <Divider className='mb-3' />

            <CustomTable selectedDate={selectedDate} />

            {/* <PassbookPage includeInvestments={false} showCtas={false} selectedDate={selectedDate} /> */}
        </>
    );
};

export default ViewSpendsPage;