import { IonCol, IonRow } from "@ionic/react";
import InfoCard from '../InfoCard/InfoCard';

interface InfoCardsRowProps {
    section: any;
    handleClick?: Function;
}

const InfoCardsRow: React.FC<InfoCardsRowProps> = ({ section, handleClick }) => (
    <>
        {section.title && <label className={`form-label ${section.titleClassName}`}>{section.title}</label>}
        <IonRow style={section.style} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            {section.items.map((item: any, index: number) => (
                <IonCol size={item.colSize} key={index} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <InfoCard {...item} handleClick={handleClick} />
                </IonCol>
            ))}
        </IonRow>
    </>
);

export default InfoCardsRow;
