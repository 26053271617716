export const mapSpendPayload = (payload: any) => {
    return {
        type: payload.type,
        accountNumber: payload.accountNumber,
        amount: payload.amount,
        date: payload.date,
        note: payload.note,
        customerName: payload.customerName,
        transactionMadeFrom: payload.transactionMadeFrom !== 'none' ? payload.transactionMadeFrom : '',
    };
}

export const getSpendId = (payload: any = {}) => {
    return `${payload.type}_${new Date().getTime()}`;
};