import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import useRedirect from '../../hooks/useRedirection';

const indicatorStyles = {
    background: '#fff',
    width: 8,
    height: 8,
    display: 'inline-block',
    margin: '0 8px',
    borderRadius: 4
};

export interface CarouselProps {
    onClick?: Function;
    onImageChange?: any;
    autoPlay?: boolean;
    infiniteLoop?: boolean;
    items?: any;
    imageUrl?: string;

}

const CustomCarousel: React.FC<CarouselProps> = (props: CarouselProps) => {
    const { redirectTo } = useRedirect();

    return (
        <>
            <Carousel
                onChange={props?.onImageChange}
                autoPlay={props?.autoPlay}
                infiniteLoop={props?.infiniteLoop}
                showThumbs={false}
                showStatus={false}
                showIndicators={true}
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                    if (props?.items?.length < 2) {
                        return null;
                    }
                    if (isSelected) {
                        return (
                            <li style={{ ...indicatorStyles, background: '#00B9F5' }}
                                aria-label={`Selected: ${label} ${index + 1}`}
                                title={`Selected: ${label} ${index + 1}`} />
                        );
                    }
                    return (
                        <li style={indicatorStyles}
                            onClick={onClickHandler}
                            onKeyDown={onClickHandler}
                            value={index}
                            key={index}
                            role="button"
                            tabIndex={0}
                            title={`${label} ${index + 1}`}
                            aria-label={`${label} ${index + 1}`} />
                    );
                }}
                {...props}>
                {props?.items?.map((item: any, index: number) => {
                    return <div key={index}
                        onClick={() => {
                            props?.onClick && props?.onClick(item, index);
                            redirectTo(item);
                        }}>
                        {item.imageUrl && <img loading='lazy' style={item.style} src={item.imageUrl} height={item.height} width={item.width}/>}
                        {item.videoUrl &&
                            <iframe allow="autoplay"
                                src={item.videoUrl}>
                            </iframe>
                        }
                        <div className={item.labelClass}>{item.label}</div>
                    </div>;
                })}
            </Carousel>
        </>
    )
}
export default CustomCarousel;