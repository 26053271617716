import TransactionDetailsPage from './TransactionDetailsPage';

const TransactionDetails: React.FC<any> = ({ pageConfig }) => {

    return (
        <div className="ion-padding">
            <TransactionDetailsPage {...pageConfig} />
        </div>
    )
}
export default TransactionDetails;