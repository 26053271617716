import AllSections from '../../components/AllSections/AllSections';
import useSignIn from '../../hooks/useSignIn';
import useRoute from '../../hooks/useRoute';
import { toastService } from '../../services/toast.service';
import useRedirect from '../../hooks/useRedirection';
import { setUserDetails } from '../../managers/user.manager';
import { ROUTES } from '../../constants/routes.constant';
import { FirebaseService } from '../../services/firebase.service';

interface VerifyOtpPageProps {
    pageConfig: any;
}

const VerifyOtpPage: React.FC<VerifyOtpPageProps> = ({ pageConfig }) => {
    const { verifyOtp } = useSignIn();
    const { queryParams } = useRoute();
    const { redirectTo } = useRedirect();

    return (
        <AllSections {...pageConfig?.VERIFY_OTP} handleClick={(section: any, formData: any) => {
            verifyOtp({ ...formData, verificationId: queryParams.verificationId }, async (resp: any) => {
                try {
                    setUserDetails(resp);
                    await FirebaseService.loginOrSignUpUser(resp.uid);
                    redirectTo({ redirectionUrl: ROUTES.DASHBOARD.path }, true);
                } catch (error: any) {
                    toastService.showErrorToast({ message: error.message });
                }
            }, (err: any) => {
                toastService.showErrorToast({ message: err.message });
            });
        }} />
    );
};

export default VerifyOtpPage;
