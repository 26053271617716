import { FirbaseDbService } from './FirebaseDB.service';

export const FirebaseService = {
    loginOrSignUpUser: async (uid: string) => {
        if (!await FirebaseService.checkIfUidPresentInDb(uid)) {
            return await FirbaseDbService.addDataInFirestoreDb('users', uid);
        }
        return true;
    },

    checkIfUidPresentInDb: async (uid: string) => {
        return await FirbaseDbService.checkIfDocExists('users', uid) ? true : false;
    }

}