export const CALENDAR_CONSTANTS = {

    DAYS: <any>{
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday'
    },

    MONTHS: <any>{
        1: 'Jan',
        2: 'Feb',
        3: 'Mar',
        4: 'Apr',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'Aug',
        9: 'Sep',
        10: 'Oct',
        11: 'Nov',
        12: 'Dec'
    },

    DATE_SUFFIX: <any>{
        // 1: 'st',
        // 2: 'nd',
        // 3: 'rd',
        // 11: 'th',
        // 12: 'th',
        // 13: 'th',
        // default: 'th'
        default: ''
    }
    
}