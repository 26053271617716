import { IonSearchbar } from '@ionic/react';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import CustomList from '../../components/CustomList/CustomList';
import { ROUTES } from '../../constants/routes.constant';
import useEarningManager from '../../hooks/useEarningManager';
import useSpendManager from '../../hooks/useSpendManager';
import useRedirect from '../../hooks/useRedirection';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import Divider from '@mui/joy/Divider';
import { getPassbookData } from '../../mapper/passbook.mapper';
import { filterItemsByMonth } from '../../utils/common.util';
import { toastService } from '../../services/toast.service';

const PassbookPage: React.FC<any> = ({
    includeInvestments = false,
    showCtas = true,
    selectedDate
}) => {
    const [items, setItems] = useState<any>({});
    const { deleteSpend } = useSpendManager();
    const { deleteEarning } = useEarningManager();
    const [searchText, setSearchText] = useState('');
    const { redirectTo } = useRedirect();

    const handleSearch = (e: any) => {
        setSearchText(e.target.value);
    };

    const filterItemsBySearchText = (items: any, filterKeys = ['customerName', 'type', 'accountNumber', 'amount', 'date', 'note', 'id']) => {
        const filteredItems = {};
        Object.keys(items).forEach((month: any) => {
            const filteredMonthItems = items[month].filter((item: any) =>
                filterKeys.some((key: string) =>
                    typeof item[key] === 'string' && item[key].toLowerCase().includes(searchText.toLowerCase())
                )
            );
            if (filteredMonthItems.length > 0) {
                filteredItems[month] = filteredMonthItems;
            }
        });
        return filteredItems;
    };

    const filteredItems = (items: any) => {
        let result = items;
        if (selectedDate) {
            result = filterItemsByMonth(result, selectedDate);
        }
        if (searchText) {
            result = filterItemsBySearchText(result);
        }
        return result;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { allItems } = await getPassbookData(includeInvestments, selectedDate, true);
                setItems(allItems);
            } catch (error) {
                setItems({});
            }
        };

        fetchData();
    }, []);

    const onDelete = (item) => {
        const { type } = item;
        if (confirm('Are you sure you want to delete this spend item?')) {
            const deleteAction = type === 'earning' ? deleteEarning : deleteSpend;
            deleteAction(item.id, (resp: any) => {
                toastService.showErrorToast({ message: resp.message });
                setItems((prevItems: any) => {
                    const updatedItems = { ...prevItems };
                    const month = new Date(item.date).toLocaleString('default', { month: 'long', year: 'numeric' });
                    updatedItems[month] = updatedItems[month].filter((_item: any) => _item.id !== item.id);
                    if (updatedItems[month].length === 0) {
                        delete updatedItems[month];
                    }
                    return updatedItems;
                });
            }, (err: any) => {
                alert(err.message);
            });
        }
    }

    const onEdit = (item) => {
        const { type } = item;
        const redirectionUrl = type === 'earning' ? ROUTES.EDIT_EARNING.path : ROUTES.EDIT_SPEND.path;
        redirectTo({ redirectionUrl }, false, { ...item });
    };

    const filteredItemsResult = filteredItems(items);

    return (
        <>
            {showCtas && <div>
                <Card variant="soft" sx={{ marginBottom: 2 }}>
                    <CardActions sx={{ paddingTop: 0 }}>
                        <Button
                            onClick={() => redirectTo({ redirectionUrl: ROUTES.ADD_EARNINGS.path })}
                            variant="solid" size="md" sx={{ width: '100%' }}>
                            Add Earnings
                        </Button>
                        <Button
                            onClick={() => redirectTo({ redirectionUrl: ROUTES.ADD_SPEND.path })}
                            variant="solid" size="md" color='danger' sx={{ width: '100%' }}>
                            Add Expense
                        </Button>
                    </CardActions>
                </Card>

                <Typography variant="h6" >PASSBOOK</Typography>
                <Divider className='mb-1' />
            </div>}

            <IonSearchbar className='pt-4 pb-4 pl-0 pr-0' value={searchText} onIonChange={handleSearch} placeholder={'Search'} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}></IonSearchbar>

            {
                Object.keys(filteredItemsResult).length === 0 ? <Typography variant="caption">No Transactions Found</Typography> :
                    <>
                        {Object.keys(filteredItemsResult).map((month: any) => (
                            <div key={month}>
                                {!includeInvestments && <Typography className='mt-3'>{month}</Typography>}
                                <CustomList
                                    hasDeleteCta={true}
                                    hasEditCta={true}
                                    items={filteredItemsResult[month]}
                                    type='MUI_LIST'
                                    onDelete={onDelete}
                                    onEdit={onEdit}
                                />
                            </div>
                        ))}
                    </>
            }
        </>
    )
};

export default PassbookPage;