import { LOCAL_STORAGE_CONSTANT } from "../constants/localStorage.constant";
import { LocalStorageUtils } from "../utils/localStorage.util";
import { FirbaseDbService } from "./FirebaseDB.service";

export const CommonService = {
    getRawData: async (key?: string) => {
        try {
            let userData: any = await FirbaseDbService.getDataFromFirestoreDb('users', LocalStorageUtils.getValueFromLocalStorage(LOCAL_STORAGE_CONSTANT.UID));
            if (key) {
                return userData[key] || {};
            }
            return userData || {};
        } catch (error) {
            throw error;
        }
    },
}