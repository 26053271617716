import { Share } from '@capacitor/share';
// import { ImageService } from './Image.service';

export const ShareService = {
    shareExternally: async (shareObj: {
        title: string,
        text: string,
        url: string,
        dialogTitle: string,
        files?: Array<string>
    }) => {
        // const file = await ImageService.convertFileUrlToBlob();
        // shareObj.files = ["file:///Users/amanjain/Desktop/Screenshot%202022-12-20%20at%2010.35.53%20AM.png"];
        // console.log(shareObj, 'shareObj')
        Share.share(shareObj);
    }
}