export const mapEarningPayload = (payload: any) => {
    return {
        type: 'earning',
        subType: payload.subType,
        accountNumber: payload.accountNumber,
        customerName: '', // removed from payload
        amount: payload.amount,
        date: payload.date,
        note: payload.note
    };
};

export const getEarningId = (payload: any = {}) => {
    if (payload.customerName) {
        return `${payload.accountNumber}_${payload.customerName.toLowerCase().replace(/ /g, '_')}_${new Date().getTime()}`;
    }
    return `${payload.accountNumber}_${new Date().getTime()}`;
};