import ViewSpendsPage from './ViewSpendsPage';

const ViewSpends: React.FC<any> = ({ pageConfig }) => {

    return (
        <div className="ion-padding">
            <ViewSpendsPage {...pageConfig} />
        </div>
    )
}
export default ViewSpends;