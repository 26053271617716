import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import FoodIcon from '@mui/icons-material/LocalGroceryStore';
import TravelIcon from '@mui/icons-material/TravelExplore';
import ShoppingIcon from '@mui/icons-material/ShoppingCart';
import EmiIcon from '@mui/icons-material/Money';
import ElectricityIcon from '@mui/icons-material/ChargingStation';
import GasIcon from '@mui/icons-material/GasMeter';
import InternetIcon from '@mui/icons-material/Wifi';
import MobileIcon from '@mui/icons-material/MobileFriendly';
import OthersIcon from '@mui/icons-material/OtherHouses';
import EarningIcon from '@mui/icons-material/AccountBalance';
import InvestmentIcon from '@mui/icons-material/MonetizationOn';

export const CustomIcon = (props: any) => {
    const { type } = props;
    switch (type) {
        case 'image':
            return <ImageIcon color='primary' />;
        case 'water':
            return <WorkIcon />;
        case 'beach':
            return <BeachAccessIcon />;
        case 'food':
            return <FoodIcon />;
        case 'travel':
            return <TravelIcon />;
        case 'shopping':
            return <ShoppingIcon />;
        case 'emi':
            return <EmiIcon />;
        case 'electricity':
            return <ElectricityIcon />;
        case 'gas':
            return <GasIcon />;
        case 'wifi':
            return <InternetIcon />;
        case 'mobile':
            return <MobileIcon />;
        case 'monthlyExpense':
            return <EmiIcon />;
        case 'investment':
            return <InvestmentIcon />;
        case 'earning':
            return <EarningIcon />;
        case 'others':
            return <OthersIcon />;
        default:
            return <ImageIcon />;
    }
}