import PassbookPage from './PassbookPage';

const Passbook: React.FC<any> = () => {

    return (
        <div className="ion-padding">
            <PassbookPage />
        </div>
    )
}
export default Passbook;