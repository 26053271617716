import { useEffect } from 'react';
import AddUpdateAssetPage from './AddUpdateAssetPage';

const AddUpdateAsset: React.FC<any> = ({ pageConfig }) => {

    return (
        <div className="ion-padding">
            <AddUpdateAssetPage {...pageConfig} />
        </div>
    )
}
export default AddUpdateAsset;